import axios from "axios";
import { store } from "../core/data/redux/store";
import secureLocalStorage from "react-secure-storage";
const localStorage = secureLocalStorage;

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL || "http://127.0.0.1:8000",
});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const authToken = state.auth?.currentUser?.token;

    const accessToken = localStorage.getItem("accessToken") || authToken;
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 401 &&
      !window.location.href.includes("/login")
    ) {
      if (!window.location.href.includes("/maintenance/500"))
        window.location = "/maintenance/500";
    }
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default axiosServices;

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.get(url, { ...config });

  return res.data;
};

export const fetcherPost = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.post(url, { ...config });

  return res.data;
};
