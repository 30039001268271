import { createAsyncThunk } from "@reduxjs/toolkit";
import ContactService from "../../../../services/contacts";

// Contact Actions
export const getContacts = createAsyncThunk(
  "contact/retrieve-contacts",
  async (payload: any) => {
    const response = await ContactService.getContacts(payload);
    if (!response) {
      throw new Error("Retrieve Contacts Failed");
    }

    return response;
  }
);

export const filterSortContacts = createAsyncThunk(
  "contact/filter-sort-contacts",
  async (payload: any) => {
    const { filters, term, order, currentPage, pageSize } = payload;
    const response = await ContactService.filterSortContacts(
      filters,
      term,
      order,
      currentPage,
      pageSize
    );
    if (!response) {
      throw new Error("Filter Contacts Failed");
    }

    return response;
  }
);

export const getContact = createAsyncThunk(
  "contact/retrieve-contact",
  async (payload: any) => {
    const response = await ContactService.getContact(payload);
    if (!response) {
      throw new Error("Retrieve Contact Failed");
    }

    return response;
  }
);

export const createContact = createAsyncThunk(
  "contact/create-contact",
  async (payload: any) => {
    const response = await ContactService.createContact(payload);
    if (!response) {
      throw new Error("Create Contact Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const editContact = createAsyncThunk(
  "contact/edit-contact",
  async (payload: any) => {
    const response = await ContactService.editContact(payload);
    if (!response) {
      throw new Error("Edit Contact Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const deleteContact = createAsyncThunk(
  "contact/delete-contact",
  async (payload: any) => {
    const response = await ContactService.removeContact(payload);
    if (!response) {
      throw new Error("Delete Contact Failed!");
    }

    return response;
  }
);
