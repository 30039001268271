import axios from "../utils/axios";

// third-party
import { Chance } from "chance";
import { jwtDecode } from "jwt-decode";
import { setSession } from "../utils/helper";
import secureLocalStorage from "react-secure-storage";
const localStorage = secureLocalStorage;

const chance = new Chance();

class AuthService {
  async refreshToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      const verifyTokenResponse = await axios.post("/api/auth/token/verify/", {
        token: refreshToken,
      });
      if (verifyTokenResponse.status == 200) {
        const refreshTokenResponse = await axios.post(
          "/api/auth/token/refresh/",
          { refresh: refreshToken }
        );
        setSession(refreshTokenResponse?.data?.access, refreshToken);
        return true;
      } else {
        setSession(null, null);
        return false;
      }
    } else {
      setSession(null, null);
      return false;
    }
  }

  async verifyToken(accessToken) {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    if (decoded.exp > Date.now() / 1000) {
      return true;
    } else {
      return await this.refreshToken();
    }
  }

  async login(email, password) {
    try {
      const response = await axios.post("api/auth/login/", {
        email,
        password,
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async checkIpRestriction() {
    const response = await axios.get("api/auth/ip-restriction/");
    return response;
  }

  async register(email, password, firstName, lastName) {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post("/api/auth/register/", {
      id,
      email,
      password,
      firstName,
      lastName,
    });
    let users = response.data;

    if (
      localStorage.getItem("users") !== undefined &&
      localStorage.getItem("users") !== null
    ) {
      const localUsers = localStorage.getItem("users");
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`,
        },
      ];
    }

    localStorage.setItem("users", JSON.stringify(users));
  }

  async logout() {
    await axios.post("/api/auth/logout/");
  }

  async getUsers() {
    try {
      const response = await axios.get("/api/auth/users/");
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async getUser() {
    try {
      const response = await axios.get("/api/auth/user/");
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async createUser(payload) {
    try {
      const response = await axios.post("/api/auth/users/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async retrieveUser(id) {
    try {
      await axios.get(`/api/auth/users/${id}/`);
      return id;
    } catch (err) {
      return null;
    }
  }

  async editUser(payload) {
    const { id, data } = payload;
    try {
      const response = await axios.put(`/api/auth/users/${id}/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async removeUser(id) {
    try {
      await axios.delete(`/api/auth/users/${id}/`);
      return id;
    } catch (err) {
      return null;
    }
  }

  async getPermissions() {
    try {
      const response = await axios.get("/api/auth/permissions/");
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async getUserGroups() {
    try {
      const response = await axios.get("/api/auth/user-groups/");
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async createUserGroup(payload) {
    try {
      const response = await axios.post("/api/auth/user-groups/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async retrieveUserGroup(id) {
    try {
      await axios.get(`/api/auth/user-groups/${id}/`);
      return id;
    } catch (err) {
      return null;
    }
  }

  async editUserGroup(payload) {
    const { id, data } = payload;
    try {
      const response = await axios.put(`/api/auth/user-groups/${id}/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async removeUserGroup(id) {
    try {
      await axios.delete(`/api/auth/user-groups/${id}/`);
      return id;
    } catch (err) {
      return null;
    }
  }

  async resetPassword(email) {
    try {
      const response = await axios.post("/api/auth/password/reset/", { email });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async resetConfirmPassword(password1, password2, uid, token) {
    try {
      const response = await axios.post("/api/auth/password/reset/confirm/", {
        new_password1: password1,
        new_password2: password2,
        uid: uid,
        token: token,
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }
}

export default new AuthService();
