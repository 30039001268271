import React from "react";
import ImageWithBasePath from "../../core/common/imageWithBasePath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert } from "antd";
import { useDispatch } from "react-redux";
import { getErrorMessage } from "../../utils/helper";
import { resetPassword } from "../../core/data/redux/auth/thunk";

const ForgotPassword = () => {
  const route = all_routes;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  return (
    <div className="account-content">
      <div className="login-wrapper account-bg forgot-bg">
        <div className="login-content">
          <Formik
            initialValues={{
              email: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const { email } = values;
                const res = await dispatch(resetPassword({ email }));

                if (res?.error) {
                  setErrors({ submit: getErrorMessage(res?.error) });
                } else {
                  navigate("/login");
                }
              } catch (err) {
                setErrors({ submit: getErrorMessage(err) });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="login-user-info">
                  <div className="login-logo">
                    <ImageWithBasePath
                      src="assets/img/logo.png"
                      className="img-fluid"
                      alt="Logo"
                    />
                  </div>
                  <div className="login-heading">
                    <h4>Forgot Password?</h4>
                    <p>
                      If you forgot your password, well, then we’ll email you
                      instructions to reset your password.
                    </p>
                  </div>
                  <div className="form-wrap">
                    <label className="col-form-label">Email Address</label>
                    <div className="form-wrap-icon">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <i className="ti ti-mail" />
                    </div>
                  </div>
                  {touched.email && errors.email && (
                    <div className="form-wrap">
                      <Alert message={errors.email} type="error" />
                    </div>
                  )}
                  <div className="form-wrap">
                    <button type="submit" className="btn btn-primary">
                      Send
                    </button>
                  </div>
                  {errors.submit && (
                    <div className="form-wrap">
                      <Alert message={errors.submit} type="error" />
                    </div>
                  )}
                  <div className="login-form text-center">
                    <h6>
                      Return to
                      <Link to={route.login} className="hover-a">
                        {" "}
                        Log In
                      </Link>
                    </h6>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
