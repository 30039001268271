import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { IconsData } from "../../data/json/icons";
import "../../../style/icon/themify/themify.css";
import "./index.scss";

interface IconSelectorProps {
  data?: string;
  show: boolean;
  setShow: (payload: boolean) => void;
  buttonText?: string;
  selectData: (payload: string | undefined | null) => void;
}

const IconSelector = ({
  data,
  buttonText,
  show,
  setShow,
  selectData,
}: IconSelectorProps) => {
  const [selected, setSelected] = useState<string | undefined>(data);

  useEffect(() => {
    setSelected(data);
  }, [data]);

  const popoverTitle = (
    <Popover
      id="popover-icon-selector"
      style={{ maxWidth: "289px", maxHeight: "300px" }}
    >
      <Popover.Body style={{ height: "300px" }}>
        <div
          className="row"
          style={{ flexDirection: "column", height: "100%" }}
        >
          <div className="col-md-12 mb-2">
            <button
              className="btn btn-success"
              type="button"
              onClick={() => {
                if (selected) {
                  selectData && selectData(selected);
                  setShow(false);
                }
              }}
            >
              Select
            </button>
            <button
              className="btn btn-danger"
              style={{
                marginLeft: "8px",
              }}
              type="button"
              onClick={() => selectData && selectData(null)}
            >
              Discard
            </button>
          </div>
          <div className="col-md-12" style={{ flex: 1, overflowY: "auto" }}>
            <div className="icons-items">
              <ul className="icons-list">
                {IconsData.map((icon: string) => (
                  <li
                    key={icon}
                    role="button"
                    className={
                      selected === icon ? "bg-success text-white" : "text-dark"
                    }
                    onClick={() => setSelected(icon)}
                  >
                    <i
                      className={`${icon}`}
                      data-bs-toggle="tooltip"
                      title={icon}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="btn-list popover-list">
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        show={show}
        overlay={popoverTitle}
      >
        <ul className="icons-list">
          {data ? (
            <li
              role="button"
              className={"text-dark"}
              onClick={() => {
                if (setShow) {
                  setShow(!show);
                  if (show) {
                    setSelected(data ? data : undefined);
                  }
                }
              }}
            >
              <i className={`${data}`} data-bs-toggle="tooltip" title={data} />
            </li>
          ) : (
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                if (setShow) {
                  setShow(!show);
                  if (show) {
                    setSelected(data ? data : undefined);
                  }
                }
              }}
            >
              <span>{buttonText || "Select Icon"}</span>
            </button>
          )}
        </ul>
      </OverlayTrigger>
    </div>
  );
};

export default IconSelector;
