import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../core/common/dataTable/index";
import { useDispatch, useSelector } from "react-redux";
import CollapseHeader from "../../../core/common/collapse-header";
import {
  setEditingContact,
  setRemoveContact,
} from "../../../core/data/redux/contacts/contacts";
import AddContactModal from "../../../core/modals/contacts/manage/add_contact_modal";
import RemoveContactModal from "../../../core/modals/contacts/manage/remove_contact_modal";
import SearchInput from "../../../core/common/searchInput/searchInput";
import ContactService from "../../../services/contacts";
import pkg from "lodash";
import { SorterResult } from "antd/es/table/interface";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TablePaginationConfig } from "antd/lib/table";
import {
  filterSortContacts,
  getContacts,
} from "../../../core/data/redux/contacts/thunk";

const PAGE_ROOT = "contact";

const ManageContacts = () => {
  const dispatch = useDispatch<any>();

  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const fieldsAdminDataLoading = useSelector(
    (state: any) => state.fieldsAdmin.admin?.loading
  );

  const contactsData =
    useSelector((state: any) => state.contact.contacts?.list) || [];

  const contactsPaginationData =
    useSelector((state: any) => state.contact.contacts?.pagination) || {};

  const contactsDataLoading = useSelector(
    (state: any) => state.contact.contact?.loading
  );

  const [columns, setColumns] = useState<any[]>([]);
  const [filterColumns, setFilterColumns] = useState<any[]>([]);

  const [searchTerm, setSearchTerm] = useState<any>("");
  const [fieldSearchResults, setFieldSearchResults] = useState<{
    [key: string]: [];
  }>({});

  const [filterSearchChecked, setFilterSearchChecked] = useState<{
    [key: string]: {
      [term: string]: true;
    };
  }>({});

  const [order, setOrder] = useState<{
    [field: string]: string | null;
  }>({
    Id: "asc",
  });

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const onFieldSearch = async (field: string, value: string) => {
    if (!value || !value.length) {
      setFieldSearchResults({});
      return;
    }

    if (value?.length >= 3) {
      const response = await ContactService.searchFieldValues(field, value);

      if (!response) {
        throw new Error("Search Field Values Failed!");
      }

      setFieldSearchResults((prev) => ({
        ...(prev || {}),
        [field]: response,
      }));
    }
  };

  const onSearch = (value: string) => {
    if (!value || !value.length) {
      setSearchTerm(null);
      return;
    }

    if (value?.length >= 3) {
      setSearchTerm(value);
    }
  };

  const onFilterRecords = () => {
    dispatch(
      filterSortContacts({ filters: filterSearchChecked, term: "", order })
    );
  };

  const onFilterItemHandler = (field: string, e: any) => {
    const isFilterItemChecked = e.target.checked;
    setFilterSearchChecked((prev: any) => {
      let fieldSelectedItems: {} = {
        ...(prev?.[field] || {}),
        [e.target.value]: isFilterItemChecked,
      };

      return {
        ...prev,
        [field]: fieldSelectedItems,
      };
    });
  };

  const onPaginationHandler = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
  };

  const onSorterHandler = (sorter: SorterResult<any> | SorterResult<any>[]) => {
    if (Array.isArray(sorter)) {
      const result: {
        [key: string]: string | null;
      } = {};
      sorter.map((sortItem: SorterResult<any>) => {
        const currentOrder =
          order?.[sortItem.columnKey as string] === undefined
            ? "ascend"
            : order?.[sortItem.columnKey as string] === null
            ? null
            : order?.[sortItem.columnKey as string];
        result[sortItem.columnKey as string] =
          currentOrder === "ascend"
            ? "descend"
            : currentOrder === "descend"
            ? null
            : "ascend";
      });
      setOrder((_) => {
        return {
          ...result,
        };
      });
    } else {
      if (sorter.columnKey) {
        setOrder((_) => {
          const currentOrder =
            order?.[sorter.columnKey as string] === undefined
              ? "ascend"
              : order?.[sorter.columnKey as string] === null
              ? null
              : order?.[sorter.columnKey as string];
          return {
            [sorter.columnKey as string]:
              currentOrder === "ascend"
                ? "descend"
                : currentOrder === "descend"
                ? null
                : "ascend",
          };
        });
      }
    }
  };

  useEffect(() => {
    setPagination(contactsPaginationData);
  }, [JSON.stringify(contactsPaginationData)]);

  useEffect(() => {
    dispatch(
      filterSortContacts({
        filters: filterSearchChecked,
        term: searchTerm,
        order,
        currentPage: 1,
      })
    );
  }, [searchTerm, JSON.stringify(order)]);

  useEffect(() => {
    dispatch(
      filterSortContacts({
        filters: filterSearchChecked,
        term: searchTerm,
        order,
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      })
    );
  }, [pagination.current, pagination.pageSize]);

  useEffect(() => {
    if (
      fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"]?.["data"]?.length
    ) {
      const filters = fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"]?.[
        "data"
      ]?.filter(
        (fieldConfiguration: any) =>
          fieldConfiguration.is_filter &&
          fieldConfiguration.filter_type === "multi-select"
      );
      setFilterColumns(filters);
      const columnsData =
        fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"]?.["data"]
          ?.filter((fieldConfiguration: any) => fieldConfiguration.is_show)
          ?.map((item: any) => ({
            title: item.display_name,
            dataIndex: item.original_name,
            key: item.original_name,
            width: item.width ?? 150,
            align: "left",
            ellipsis: true,
            sorter: true,
            sortDirections: ["ascend", "descend", null],
            render: (text: string, record: any, index: number) => (
              <div
                className={`table-avatar d-flex ${
                  item.display_field_type === "email-with-icon" ||
                  item.display_field_type === "phone-with-icon"
                    ? "justify-content-center"
                    : "justify-content-start"
                } align-items-center`}
                key={index}
              >
                {record?.[item.original_name] ? (
                  item.display_field_type === "email-with-icon" ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="refresh-tooltip">
                          {record?.[item.original_name]}
                        </Tooltip>
                      }
                    >
                      <div className="mx-2">
                        <i className={"ti-email"}></i>
                      </div>
                    </OverlayTrigger>
                  ) : item.display_field_type === "phone-with-icon" ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="refresh-tooltip">
                          {record?.[item.original_name]}
                        </Tooltip>
                      }
                    >
                      <div className="mx-2">
                        <i className={"ti-headphone"}></i>
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <span className="profile-split d-flex flex-column cursor-pointer">
                      {record?.[item.original_name]}
                    </span>
                  )
                ) : null}
              </div>
            ),
          })) || [];
      setColumns([
        ...columnsData,
        {
          title: "Actions",
          dataIndex: "actions",
          render: (text: string, record: any, index: number) => (
            <div className="dropdown table-action" key={index}>
              <Link
                to="#"
                className="action-icon"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              >
                <i className="fa fa-ellipsis-v"></i>
              </Link>
              <div
                className="dropdown-menu dropdown-menu-right"
                style={{
                  position: "absolute",
                  inset: "0px auto auto 0px",
                  margin: "0px",
                  transform: "translate3d(-99.3333px, 35.3333px, 0px)",
                }}
                data-popper-placement="bottom-start"
              >
                <Link
                  className="dropdown-item edit-popup"
                  to="#"
                  onClick={() => dispatch(setEditingContact(record))}
                >
                  <i className="ti ti-edit text-blue"></i> Edit
                </Link>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => dispatch(setRemoveContact(record.contact_id))}
                >
                  <i className="ti ti-trash text-danger"></i> Delete
                </Link>
              </div>
            </div>
          ),
        },
      ]);
    }
  }, [JSON.stringify(fieldsAdminData?.[PAGE_ROOT]?.["ContactNew"]?.["main"])]);

  useEffect(() => {
    const init = async () => {
      dispatch(getContacts({}));
    };

    init();
  }, [dispatch]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* contact */}
              <div className="contact-head">
                <div className="row align-items-center"></div>
              </div>
            </div>
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">
                      Contacts
                      {contactsPaginationData ? (
                        <span className="count-title">
                          {contactsPaginationData?.total}
                        </span>
                      ) : null}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(getContacts({}));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="col-md-5 col-sm-4">
                      <div className="form-wrap icon-form">
                        <span className="form-icon">
                          <i className="ti ti-search" />
                        </span>
                        <SearchInput
                          className="form-control"
                          placeholder="Search Conact"
                          onDebounce={(value: string) => {
                            onSearch(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          <li>
                            <div className="sort-dropdown drop-down">
                              <Link
                                to="#"
                                className="dropdown-toggle"
                                data-bs-toggle="dropdown"
                              >
                                <i className="ti ti-sort-ascending-2" />
                                Sort{" "}
                              </Link>
                              <div className="dropdown-menu  dropdown-menu-start">
                                <ul>
                                  <li>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setOrder((prev) => ({
                                          ...(prev || {}),
                                          contact_id: "asc",
                                        }));
                                      }}
                                    >
                                      <i className="ti ti-circle-chevron-right" />
                                      Ascending
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setOrder((prev) => ({
                                          ...(prev || {}),
                                          contact_id: "desc",
                                        }));
                                      }}
                                    >
                                      <i className="ti ti-circle-chevron-right" />
                                      Descending
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="form-sorts dropdown">
                              <Link
                                to="#"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="false"
                              >
                                <i className="ti ti-filter-share" />
                                Filter
                              </Link>
                              <div className="filter-dropdown-menu dropdown-menu  dropdown-menu-xl-end">
                                <div className="filter-set-view">
                                  <div className="filter-set-head">
                                    <h4>
                                      <i className="ti ti-filter-share" />
                                      Filter
                                    </h4>
                                  </div>

                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    {filterColumns.map((filterCol) => (
                                      <div
                                        className="filter-set-content"
                                        key={filterCol.original_name}
                                      >
                                        <div className="filter-set-content-head">
                                          <Link
                                            to="#"
                                            className="collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${filterCol.original_name?.toLowerCase()}`}
                                            aria-expanded="false"
                                            aria-controls={`collapse-${filterCol.original_name?.toLowerCase()}`}
                                          >
                                            {filterCol.display_name}
                                          </Link>
                                        </div>
                                        <div
                                          className="filter-set-contents accordion-collapse collapse"
                                          id={`collapse-${filterCol.original_name?.toLowerCase()}`}
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div className="filter-content-list">
                                            <div className="form-wrap icon-form">
                                              <span className="form-icon">
                                                <i className="ti ti-search" />
                                              </span>
                                              <SearchInput
                                                className="form-control"
                                                placeholder={`Search ${filterCol.display_name}`}
                                                onDebounce={(value: string) => {
                                                  onFieldSearch(
                                                    filterCol.original_name,
                                                    value
                                                  );
                                                }}
                                              />
                                            </div>
                                            <ul>
                                              {(
                                                fieldSearchResults?.[
                                                  filterCol.original_name
                                                ] || []
                                              ).map((searchItem) => (
                                                <li key={searchItem}>
                                                  <div className="filter-checks">
                                                    <label className="checkboxs">
                                                      <input
                                                        type="checkbox"
                                                        value={searchItem}
                                                        id={`checkebox-${filterCol.original_name}-${searchItem}`}
                                                        checked={
                                                          filterSearchChecked?.[
                                                            filterCol
                                                              .original_name
                                                          ]?.[searchItem]
                                                        }
                                                        onChange={(e) =>
                                                          onFilterItemHandler(
                                                            filterCol.original_name,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      <span className="checkmarks" />
                                                    </label>
                                                  </div>
                                                  <div className="collapse-inside-text">
                                                    <label
                                                      htmlFor={`checkebox-${filterCol.original_name}-${searchItem}`}
                                                    >
                                                      <h5>{searchItem}</h5>
                                                    </label>
                                                  </div>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="filter-reset-btns">
                                    <div className="row">
                                      <div className="col-6">
                                        <Link
                                          to="#"
                                          className="btn btn-light"
                                          onClick={() => {
                                            setFilterSearchChecked({});
                                          }}
                                        >
                                          Reset
                                        </Link>
                                      </div>
                                      <div className="col-6">
                                        <Link
                                          to={"#"}
                                          className="btn btn-primary"
                                          onClick={() => {
                                            onFilterRecords();
                                          }}
                                        >
                                          Filter
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            {/* <Link
                              to="#"
                              className="btn btn-primary add-popup"
                              onClick={() => dispatch(setEditingContact(null))}
                            >
                              <i className="ti ti-square-rounded-plus" /> Add
                              New Contact
                            </Link> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Contact List */}
                {columns && columns.length ? (
                  <>
                    <div className="table-responsive custom-table">
                      <Table
                        dataSource={contactsData}
                        columns={columns}
                        loading={contactsDataLoading}
                        onSorterHandler={onSorterHandler}
                        pagination={{
                          position: ["bottomRight"],
                          current: pagination.current,
                          pageSize: pagination.pageSize,
                          total: pagination.total,
                          onChange: (page, pageSize) => {
                            setPagination({
                              ...pagination,
                              current: page,
                              pageSize,
                            });
                          },
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                          locale: {
                            items_per_page: "records / page",
                          },
                        }}
                        onPaginationHandler={onPaginationHandler}
                        tableLayout="fixed"
                      />
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="datatable-length" />
                      </div>
                      <div className="col-md-6">
                        <div className="datatable-paginate" />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>No Columns</div>
                )}

                {/* /Contact List */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AddContactModal />
      <RemoveContactModal /> */}
    </>
  );
};

export default ManageContacts;
