import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteMenuGroup } from "../../../data/redux/menu/thunk";
import { setRemoveGroupClose } from "../../../data/redux/menu/menu";

const RemoveGroupModal = () => {
  const dispatch = useDispatch<any>();
  const deletingRecordId = useSelector(
    (state: any) => state?.menu?.groups?.delete?.data
  );

  return (
    <>
      {/* Delete Group */}
      <div
        className={`modal custom-modal fade ${deletingRecordId ? "show" : ""}`}
        style={{ display: deletingRecordId ? "block" : "none" }}
        id="delete_group"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 m-0 justify-content-end">
              <button
                className="btn-close"
                onClick={() => dispatch(setRemoveGroupClose())}
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="ti ti-trash-x" />
                </div>
                <h3>Remove Group?</h3>
                <p className="del-info">
                  Are you sure you want to remove group you selected.
                </p>
                <div className="col-lg-12 text-center modal-btn">
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={() => dispatch(setRemoveGroupClose())}
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-danger"
                    onClick={() => dispatch(deleteMenuGroup(deletingRecordId))}
                  >
                    Yes, Delete it
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Group */}
    </>
  );
};

export default RemoveGroupModal;
