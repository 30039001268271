import { combineReducers } from "@reduxjs/toolkit";
import commonSlice from "./common";
import authSlice from "./auth/auth";
import menuSlice from "./menu/menu";
import contactSlice from "./contacts/contacts";
import fieldsAdminSlice from "./fieldsAdmin/fieldsAdmin";
import leadSlice from "./leads/leads";
import campaignSlice from "./campaign/campaign";

const appReducer = combineReducers({
  common: commonSlice,
  auth: authSlice,
  menu: menuSlice,
  contact: contactSlice,
  campaign: campaignSlice,
  lead: leadSlice,
  fieldsAdmin: fieldsAdminSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET_WEBAPP") {
    state = undefined;
  }
  return appReducer(state, action);
};

export const resetAppAction = () => (dispatch: any) => {
  dispatch({ type: "RESET_WEBAPP" });
};

export default rootReducer;
