import { createSlice } from "@reduxjs/toolkit";
import {
  createCampaign,
  editCampaign,
  getCampaign,
  getCampaigns,
  deleteCampaign,
} from "./thunk";
import { sortBy } from "lodash";

const initialState: {
  campaigns: {
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
    loading: boolean;
    list: any[];
  };
} = {
  campaigns: {
    loading: true,
    edit: {
      data: null,
      loading: false,
    },
    delete: {
      data: null,
    },
    list: [],
  },
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    // Campaign Actions
    setEditingCampaign: (state, action) => {
      state.campaigns = {
        ...(state.campaigns || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingCampaignClose: (state) => {
      state.campaigns = {
        ...(state.campaigns || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveCampaign: (state, action) => {
      state.campaigns = {
        ...(state.campaigns || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveCampaignClose: (state) => {
      state.campaigns = {
        ...(state.campaigns || {}),
        delete: {
          data: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Campaign Actions
      .addCase(createCampaign.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        const data: any = action.payload;
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: sortBy([...(state.campaigns.list || []), data], "updated_at"),
        };
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            data: null,
            loading: false,
          },
        };
      })
      .addCase(editCampaign.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            ...(state.campaigns.edit || {}),
            loading: true,
          },
        };
      })
      .addCase(editCampaign.fulfilled, (state, action) => {
        const data: any = action.payload;
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: sortBy(
            [
              ...state.campaigns.list?.filter(
                (item) => item.campaign_id !== data.campaign_id
              ),
              data,
            ],
            "updated_at"
          ),
        };
      })
      .addCase(editCampaign.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(getCampaign.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: true,
        };
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        const data = action.payload;
        const existingData = (state.campaigns.list || []).filter(
          (item: any) => item.campaign_id !== data?.campaign_id
        );
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
          list: sortBy([...existingData, data], "updated_at"),
        };
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
        };
      })
      .addCase(getCampaigns.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: true,
        };
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        const CampaignsData = action.payload;
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
          list: sortBy(CampaignsData, "updated_at"),
        };
      })
      .addCase(getCampaigns.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
        };
      })
      .addCase(deleteCampaign.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: true,
        };
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        const CampaignId = action.payload;
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
          list: (state.campaigns.list || []).filter(
            (item: any) => item.campaign_id !== CampaignId
          ),
          delete: {
            data: null,
          },
        };
      })
      .addCase(deleteCampaign.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
        };
      });
  },
});

export const {
  setEditingCampaign,
  setEditingCampaignClose,
  setRemoveCampaign,
  setRemoveCampaignClose,
} = campaignSlice.actions;

export default campaignSlice.reducer;
