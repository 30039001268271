import React, { useState } from "react";
import ImageWithBasePath from "../../core/common/imageWithBasePath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import { login } from "../../core/data/redux/auth/thunk";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert } from "antd";
import { useDispatch } from "react-redux";
import { getErrorMessage } from "../../utils/helper";

const Login = () => {
  const route = all_routes;
  const dispatch = useDispatch<any>();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  return (
    <div className="account-content">
      <div className="login-wrapper account-bg">
        <div className="login-content">
          <Formik
            initialValues={{
              email: "",
              password: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const { email, password } = values;
                const res = await dispatch(login({ email, password }));
                if (res?.error) {
                  setErrors({ submit: getErrorMessage(res?.error) });
                } else {
                  navigate("/welcome");
                }
              } catch (err) {
                setErrors({ submit: getErrorMessage(err) });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="login-user-info">
                  <div className="login-logo">
                    <ImageWithBasePath
                      src="assets/img/logo.png"
                      className="img-fluid"
                      alt="Logo"
                    />
                  </div>
                  <div className="login-heading">
                    <h4>Sign In</h4>
                    <p>
                      Access the BizRamp Portal using your email and passcode.
                    </p>
                  </div>
                  <div className="form-wrap">
                    <label className="col-form-label">Email Address</label>
                    <div className="form-wrap-icon">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <i className="ti ti-mail" />
                    </div>
                  </div>
                  {touched.email && errors.email && (
                    <div className="form-wrap">
                      <Alert message={errors.email} type="error" />
                    </div>
                  )}
                  <div className="form-wrap">
                    <label className="col-form-label">Password</label>
                    <div className="pass-group">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        className="pass-input form-control"
                        name="password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <span
                        className={`ti toggle-password ${
                          isPasswordVisible ? "ti-eye" : "ti-eye-off"
                        }`}
                        onClick={togglePasswordVisibility}
                      ></span>
                    </div>
                  </div>
                  {touched.password && errors.password && (
                    <div className="form-wrap">
                      <Alert message={errors.password} type="error" />
                    </div>
                  )}
                  <div className="form-wrap form-wrap-checkbox">
                    <div className="custom-control custom-checkbox">
                      <label className="check">
                        <input type="checkbox" />
                        <span className="box" /> Remember Me
                      </label>
                    </div>
                    <div className="text-end">
                      <Link to={route.forgotPassword} className="forgot-link">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  {errors.submit && (
                    <div className="form-wrap">
                      <Alert message={errors.submit} type="error" />
                    </div>
                  )}
                  <div className="form-wrap">
                    <button type="submit" className="btn btn-primary">
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
