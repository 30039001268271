import React from "react";

import { Outlet } from "react-router";


const AuthFeature = () => {
  return (
    <Outlet/>
  );
};

export default AuthFeature;
