import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setRemoveFieldsAdminClose } from "../../../data/redux/fieldsAdmin/fieldsAdmin";
import { deleteAdminField } from "../../../data/redux/fieldsAdmin/thunk";

const RemoveFieldAdminModal = () => {
  const dispatch = useDispatch<any>();
  const deletingRecordId = useSelector(
    (state: any) => state?.fieldsAdmin.admin?.delete?.data
  );

  return (
    <>
      {/* Delete Contact Admin */}
      <div
        className={`modal custom-modal fade ${deletingRecordId ? "show" : ""}`}
        style={{ display: deletingRecordId ? "block" : "none" }}
        id="delete_contact_type"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 m-0 justify-content-end">
              <button
                className="btn-close"
                onClick={() => dispatch(setRemoveFieldsAdminClose())}
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="ti ti-trash-x" />
                </div>
                <h3>Remove Field Admin?</h3>
                <p className="del-info">
                  Are you sure you want to remove field admin you selected.
                </p>
                <div className="col-lg-12 text-center modal-btn">
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={() => dispatch(setRemoveFieldsAdminClose())}
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-danger"
                    onClick={() => dispatch(deleteAdminField(deletingRecordId))}
                  >
                    Yes, Delete it
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Contact Admin */}
    </>
  );
};

export default RemoveFieldAdminModal;
