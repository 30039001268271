import { debounce } from "lodash";
import React, { useState, useEffect, useCallback } from "react";

interface DebouncedInputProps {
  onDebounce: (payload: string) => void;
  placeholder?: string;
  className?: string;
}

const DebouncedInput = ({
  onDebounce,
  placeholder,
  className,
}: DebouncedInputProps) => {
  const [inputValue, setInputValue] = useState("");

  // Create a debounced version of the function using useCallback to memoize it
  const debouncedChangeHandler = useCallback(
    debounce((value) => {
      onDebounce(value);
    }, 500), // Adjust the debounce delay as needed
    []
  );

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    debouncedChangeHandler(e.target.value);
  };

  // Clean up debounce on component unmount
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleChange}
      className={className || ""}
      placeholder={placeholder || "Type something..."}
    />
  );
};

export default DebouncedInput;
