import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Table from "../../../core/common/dataTable/index";
import { useDispatch, useSelector } from "react-redux";
import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import AddMenuModal from "../../../core/modals/menus/menu/add_menu_modal";
import AddMenuSuccessModal from "../../../core/modals/menus/menu/add_menu_success_modal";
import { Flex, Tag } from "antd";
import {
  setEditingMenu,
  setRemoveMenu,
} from "../../../core/data/redux/menu/menu";
import RemoveMenuModal from "../../../core/modals/menus/menu/remove_menu_modal";
import { findParents } from "../../../utils/helper";
import { editMenu, getMenus } from "../../../core/data/redux/menu/thunk";

const MenuDetail = () => {
  const route = all_routes;

  const menuData = useSelector((state: any) => state.menu.menus?.list) || [];
  const groupData = useSelector((state: any) => state.menu.groups?.list) || [];

  const groupsLoading = useSelector((state: any) => state.menu.groups?.loading);
  const menusLoading = useSelector((state: any) => state.menu.menus?.loading);

  const dispatch = useDispatch<any>();
  let { id } = useParams();

  const [record, setRecord] = useState<any>();
  const [childMenus, setChildMenus] = useState<any[]>([]);

  const [parentsList, setParents] = useState<any[]>([]);

  // Call initializeStarsState once when the component mounts
  useEffect(() => {
    if (menuData?.length) {
      const record = menuData.find((menu: any) => menu.id === id);
      const menus = menuData.filter((menu: any) => menu.parent === id);
      setRecord(record);
      setChildMenus(menus || record?.menus || []);
    }
  }, [id, JSON.stringify(menuData)]);

  useEffect(() => {
    if (record) {
      const parents = findParents(record, groupData, menuData);
      setParents(parents.reverse());
    }
  }, [JSON.stringify(record)]);

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 60,
      className: "drag-visible",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          {record.level < 3 ? (
            <Link
              to={route.menuDetail.replace(":id", record.id)}
              className="profile-split d-flex flex-column cursor-pointer"
              role="button"
            >
              <span>{record.name}</span>
            </Link>
          ) : (
            <span className="profile-split d-flex flex-column cursor-pointer">
              {record.name}
            </span>
          )}
        </div>
      ),
    },
    {
      title: "URL",
      dataIndex: "url",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <Link
            to={record.url}
            className="profile-split d-flex flex-column cursor-pointer"
            role="button"
          >
            <span>{record.url}</span>
          </Link>
        </div>
      ),
    },

    {
      title: "Assigned Groups",
      dataIndex: "assigned_user_groups",
      render: (text: string, record: any, index: number) => (
        <Flex gap="4px 0">
          {(record?.assigned_user_groups || []).map((group: any) => (
            <Tag color="processing" key={group.name}>
              {group.name}
            </Tag>
          ))}
        </Flex>
      ),
    },
    {
      title: "Category",
      dataIndex: "menu_page_category",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span>{record.menu_page_category}</span>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text: string, record: any, index: number) => (
        <div className="dropdown table-action" key={index}>
          <Link
            to="#"
            className="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="fa fa-ellipsis-v"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              margin: "0px",
              transform: "translate3d(-99.3333px, 35.3333px, 0px)",
            }}
            data-popper-placement="bottom-start"
          >
            <Link
              className="dropdown-item edit-popup"
              to="#"
              onClick={() => dispatch(setEditingMenu(record))}
            >
              <i className="ti ti-edit text-blue"></i> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => dispatch(setRemoveMenu(record.id))}
            >
              <i className="ti ti-trash text-danger"></i> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const onDragEnd = async (recordId: string, newOrder: number) => {
    const record = menuData?.find((item: any) => item?.id === recordId);
    await dispatch(
      editMenu({
        id: recordId,
        data: {
          ...(record || {}),
          assigned_user_groups_ids: record.assigned_user_groups.map(
            (item: any) => item.id
          ),
          order: newOrder + 1,
        },
        is_new_order: true,
      })
    );
    dispatch(getMenus());
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Contact User */}
              <div className="contact-head">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <ul className="contact-breadcrumb">
                      {parentsList.map((parent: any, index: number) => (
                        <li key={parent.data.id}>
                          <Link
                            to={
                              parent.is_group
                                ? route.groupDetail.replace(
                                    ":id",
                                    parent.data.id
                                  )
                                : route.menuDetail.replace(
                                    ":id",
                                    parent.data.id
                                  )
                            }
                          >
                            {index === 0 ? (
                              <i className="ti ti-arrow-narrow-left" />
                            ) : null}
                            {parent.data?.name}
                          </Link>
                        </li>
                      ))}
                      <li>{record?.name}</li>
                    </ul>
                  </div>
                  {/* <div className="col-sm-6 text-sm-end">
                    <div className="contact-pagination">
                      <p>1 of 40</p>
                      <ul></ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">
                      Menus
                      {childMenus?.length ? (
                        <span className="count-title">
                          {childMenus?.length}
                        </span>
                      ) : null}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(getMenus());
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="col-md-5 col-sm-4">
                      <div className="form-wrap icon-form">
                        <span className="form-icon">
                          <i className="ti ti-search" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Menus"
                        />
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          <li>
                            <Link
                              to="#"
                              className="btn btn-primary add-popup"
                              onClick={() => dispatch(setEditingMenu(null))}
                            >
                              <i className="ti ti-square-rounded-plus" /> Add
                              Menu
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Group List */}
                <div className="table-responsive custom-table">
                  <Table
                    dataSource={childMenus}
                    columns={columns}
                    onDragEnd={onDragEnd}
                    loading={groupsLoading || menusLoading}
                  />
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="datatable-length" />
                  </div>
                  <div className="col-md-6">
                    <div className="datatable-paginate" />
                  </div>
                </div>
                {/* /Group List */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddMenuModal parent={record} level={(record?.level || 1) + 1} />
      <AddMenuSuccessModal />
      <RemoveMenuModal />
    </>
  );
};

export default MenuDetail;
