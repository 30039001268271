import PropTypes from "prop-types";
import { createContext, useEffect } from "react";

// third-party
import { useDispatch, useSelector } from "react-redux";
import { Preloader } from "../core/common/loader";
import { initAuth, login, logout } from "../core/data/redux/auth/thunk";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const loginHandler = (email, password) => dispatch(login(email, password));
  const logoutHandler = () => dispatch(logout());

  useEffect(() => {
    const init = async () => {
      dispatch(initAuth());
    };

    init();
  }, [dispatch]);

  const updateProfile = () => {};

  if (
    auth?.currentUser?.isInitialized !== undefined &&
    !auth?.currentUser?.isInitialized
  ) {
    return <Preloader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        login: loginHandler,
        logout: logoutHandler,
        // register,
        // resetPassword,
        // resetConfirmPassword,
        // updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
