import { createSlice } from "@reduxjs/toolkit";
import {
  getMenuGroups,
  createMenuGroup,
  editMenuGroup,
  deleteMenuGroup,
  getMenuGroup,
  createMenu,
  editMenu,
  getMenu,
  getMenus,
  deleteMenu,
} from "./thunk";
import { sortBy } from "lodash";

const initialState: {
  groups: {
    loading: boolean;
    list: any[];
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
  };
  menus: {
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
    view: {
      data: any;
    };
    loading: boolean;
    list: any[];
  };
} = {
  groups: {
    loading: false,
    list: [],
    edit: {
      data: null,
      loading: false,
    },
    delete: {
      data: null,
    },
  },
  menus: {
    loading: true,
    edit: {
      data: null,
      loading: false,
    },
    view: {
      data: null,
    },
    delete: {
      data: null,
    },
    list: [],
  },
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    // Group Actions
    setEditingGroup: (state, action) => {
      state.groups = {
        ...(state.groups || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingGroupClose: (state) => {
      state.groups = {
        ...(state.groups || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveGroup: (state, action) => {
      state.groups = {
        ...(state.groups || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveGroupClose: (state) => {
      state.groups = {
        ...(state.groups || {}),
        delete: {
          data: null,
        },
      };
    },
    // Menu Actions
    setEditingMenu: (state, action) => {
      state.menus = {
        ...(state.menus || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingMenuClose: (state) => {
      state.menus = {
        ...(state.menus || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveMenu: (state, action) => {
      state.menus = {
        ...(state.menus || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveMenuClose: (state) => {
      state.menus = {
        ...(state.menus || {}),
        delete: {
          data: null,
        },
      };
    },
    setViewMenu: (state, action) => {
      state.menus = {
        ...(state.menus || {}),
        view: {
          data: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Group Actions
      .addCase(createMenuGroup.pending, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(createMenuGroup.fulfilled, (state, action) => {
        const groupData: any = action.payload;
        state.groups = {
          ...(state.groups || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: sortBy([...state.groups.list, groupData], "order"),
        };
      })
      .addCase(createMenuGroup.rejected, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(editMenuGroup.pending, (state, action) => {
        const isNewOrder = !!action.meta.arg?.["is_new_order"];

        state.groups = {
          ...(state.groups || {}),
          edit: {
            ...(state.groups.edit || {}),
            loading: !isNewOrder,
          },
        };
      })
      .addCase(editMenuGroup.fulfilled, (state, action) => {
        const groupData: any = action.payload;
        state.groups = {
          ...(state.groups || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: sortBy(
            [
              ...state.groups.list?.filter((item) => item.id !== groupData.id),
              groupData,
            ],
            "order"
          ),
        };
      })
      .addCase(editMenuGroup.rejected, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(getMenuGroup.pending, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: true,
        };
      })
      .addCase(getMenuGroup.fulfilled, (state, action) => {
        const groupData = action.payload;
        const existingData = (state.groups.list || []).filter(
          (item: any) => item.id !== groupData?.id
        );
        state.groups = {
          ...(state.groups || {}),
          loading: false,
          list: sortBy([...existingData, groupData], "order"),
        };
      })
      .addCase(getMenuGroup.rejected, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: false,
        };
      })
      .addCase(getMenuGroups.pending, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: true,
        };
      })
      .addCase(getMenuGroups.fulfilled, (state, action) => {
        const groupsData = action.payload;
        state.groups = {
          ...(state.groups || {}),
          loading: false,
          list: sortBy(groupsData, "order"),
        };
      })
      .addCase(getMenuGroups.rejected, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: false,
        };
      })
      .addCase(deleteMenuGroup.pending, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: true,
        };
      })
      .addCase(deleteMenuGroup.fulfilled, (state, action) => {
        const groupId = action.payload;
        state.groups = {
          ...(state.groups || {}),
          loading: false,
          list: (state.groups.list || []).filter(
            (item: any) => item.id !== groupId
          ),
          delete: {
            data: null,
          },
        };
      })
      .addCase(deleteMenuGroup.rejected, (state, action) => {
        state.groups = {
          ...(state.groups || {}),
          loading: false,
        };
      })
      // Menu Actions
      .addCase(createMenu.pending, (state, action) => {
        state.menus = {
          ...(state.menus || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(createMenu.fulfilled, (state, action) => {
        const data: any = action.payload;
        state.menus = {
          ...(state.menus || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: sortBy([...(state.menus.list || []), data], "order"),
        };
      })
      .addCase(createMenu.rejected, (state, action) => {
        state.menus = {
          ...(state.menus || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(editMenu.pending, (state, action) => {
        const isNewOrder = !!action.meta.arg?.["is_new_order"];

        state.menus = {
          ...(state.menus || {}),
          edit: {
            ...(state.menus.edit || {}),
            loading: !isNewOrder,
          },
        };
      })
      .addCase(editMenu.fulfilled, (state, action) => {
        const data: any = action.payload;
        state.menus = {
          ...(state.menus || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: sortBy(
            [...state.menus.list?.filter((item) => item.id !== data.id), data],
            "order"
          ),
        };
      })
      .addCase(editMenu.rejected, (state, action) => {
        state.menus = {
          ...(state.menus || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(getMenu.pending, (state, action) => {
        state.menus = {
          ...(state.menus || {}),
          loading: true,
        };
      })
      .addCase(getMenu.fulfilled, (state, action) => {
        const data = action.payload;
        const existingData = (state.menus.list || []).filter(
          (item: any) => item.id !== data?.id
        );
        state.menus = {
          ...(state.menus || {}),
          loading: false,
          list: sortBy([...existingData, data], "order"),
        };
      })
      .addCase(getMenu.rejected, (state, action) => {
        state.menus = {
          ...(state.menus || {}),
          loading: false,
        };
      })
      .addCase(getMenus.pending, (state, action) => {
        state.menus = {
          ...(state.menus || {}),
          loading: true,
        };
      })
      .addCase(getMenus.fulfilled, (state, action) => {
        const menusData = action.payload;
        state.menus = {
          ...(state.menus || {}),
          loading: false,
          list: sortBy(menusData, "order"),
        };
      })
      .addCase(getMenus.rejected, (state, action) => {
        state.menus = {
          ...(state.menus || {}),
          loading: false,
        };
      })
      .addCase(deleteMenu.pending, (state, action) => {
        state.menus = {
          ...(state.menus || {}),
          loading: true,
        };
      })
      .addCase(deleteMenu.fulfilled, (state, action) => {
        const menuId = action.payload;
        state.menus = {
          ...(state.menus || {}),
          loading: false,
          list: (state.menus.list || []).filter(
            (item: any) => item.id !== menuId
          ),
          delete: {
            data: null,
          },
        };
      })
      .addCase(deleteMenu.rejected, (state, action) => {
        state.menus = {
          ...(state.menus || {}),
          loading: false,
        };
      });
  },
});

export const {
  setEditingGroup,
  setEditingGroupClose,
  setRemoveGroup,
  setRemoveGroupClose,
  setEditingMenu,
  setEditingMenuClose,
  setRemoveMenu,
  setRemoveMenuClose,
  setViewMenu,
} = menuSlice.actions;

export default menuSlice.reducer;
