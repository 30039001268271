import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { authRoutes, publicRoutes } from "./router.link";
import Feature from "../feature";
import AuthFeature from "../authFeature";
import Error404 from "../pages/error/error-404";
import { useSelector } from "react-redux";

const ALLRoutes: React.FC = () => {
  const isAuthenticated = useSelector(
    (state: any) => !!state.auth.currentUser.token
  );

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />

        <Route element={<AuthFeature />}>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                isAuthenticated ? <Navigate to="/welcome" /> : route.element
              }
              key={idx}
            />
          ))}
        </Route>

        <Route element={<Feature />}>
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                isAuthenticated ? route.element : <Navigate to="/login" />
              }
            />
          ))}
        </Route>

        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default ALLRoutes;
