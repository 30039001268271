import React from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Flex, Tag } from "antd";
import { format } from "date-fns";
import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import { getUsers } from "../../../core/data/redux/auth/thunk";

import Table from "../../../core/common/dataTable/index";
import {
  setEditingUser,
  setRemoveUser,
} from "../../../core/data/redux/auth/auth";
import AddUserModal from "../../../core/modals/menus/users/add_user_modal";
import AddUserSuccessModal from "../../../core/modals/menus/users/add_user_success_modal";
import RemoveUserModal from "../../../core/modals/menus/users/remove_user_modal";

const ManageUsers = () => {
  const route = all_routes;

  const usersData = useSelector((state: any) => state?.auth?.users?.data) || [];
  const userLoading =
    useSelector((state: any) => state?.auth?.users?.loading) || false;

  const dispatch = useDispatch<any>();

  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span className="profile-split d-flex flex-column cursor-pointer">
            {record.username}
          </span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span className="profile-split d-flex flex-column cursor-pointer">
            {record.email}
          </span>
        </div>
      ),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span className="profile-split d-flex flex-column cursor-pointer">
            {record.first_name}
          </span>
        </div>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span className="profile-split d-flex flex-column cursor-pointer">
            {record.last_name}
          </span>
        </div>
      ),
    },
    {
      title: "Ip Addresses",
      dataIndex: "ip_addresses",
      render: (text: string, record: any, index: number) => (
        <Flex gap="4px 0">
          {(record?.ip_addresses || "")
            .split(", ")
            .map((ip_address: string) => (
              <Tag color="processing" key={ip_address}>
                {ip_address.trim()}
              </Tag>
            ))}
        </Flex>
      ),
    },
    {
      title: "Date Joined",
      dataIndex: "date_joined",
      render: (text: string, record: any, index: number) => {
        const dateJoined = record?.date_joined
          ? new Date(record?.date_joined)
          : "";
        const parsedData = dateJoined
          ? format(dateJoined, "eeee, dd MMMM HH:mm:ss")
          : "";
        return (
          <div className="table-avatar d-flex align-items-center" key={index}>
            <span className="profile-split d-flex flex-column cursor-pointer">
              {parsedData}
            </span>
          </div>
        );
      },
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      render: (text: string, record: any, index: number) => {
        const lastLogin = record?.last_login
          ? new Date(record?.last_login)
          : "";
        const parsedData = lastLogin
          ? format(lastLogin, "eeee, dd MMMM HH:mm:ss")
          : "";
        return (
          <div className="table-avatar d-flex align-items-center" key={index}>
            <span className="profile-split d-flex flex-column cursor-pointer">
              {parsedData}
            </span>
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text: string, record: any, index: number) => (
        <div className="dropdown table-action" key={index}>
          <Link
            to="#"
            className="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="fa fa-ellipsis-v"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              margin: "0px",
              transform: "translate3d(-99.3333px, 35.3333px, 0px)",
            }}
            data-popper-placement="bottom-start"
          >
            <Link
              className="dropdown-item edit-popup"
              to="#"
              onClick={() => dispatch(setEditingUser(record))}
            >
              <i className="ti ti-edit text-blue"></i> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => dispatch(setRemoveUser(record.id))}
            >
              <i className="ti ti-trash text-danger"></i> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Contact User */}
              <div className="contact-head">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <ul className="contact-breadcrumb">
                      <li>
                        <Link to={"/"}>Home</Link>
                      </li>

                      <li>Users</li>
                    </ul>
                  </div>
                  {/* <div className="col-sm-6 text-sm-end">
                    <div className="contact-pagination">
                      <p>1 of 40</p>
                      <ul></ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">
                      Users
                      {usersData?.length ? (
                        <span className="count-title">{usersData?.length}</span>
                      ) : null}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(getUsers());
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="col-md-5 col-sm-4">
                      <div className="form-wrap icon-form">
                        <span className="form-icon">
                          <i className="ti ti-search" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Users"
                        />
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          <li>
                            <Link
                              to="#"
                              className="btn btn-primary add-popup"
                              onClick={() => dispatch(setEditingUser(null))}
                            >
                              <i className="ti ti-square-rounded-plus" /> Add
                              User
                            </Link>
                          </li>

                          <li>
                            <Link
                              to={route.userGroups}
                              className="btn btn-primary manage-user-groups"
                            >
                              <i className="ti ti-square-rounded-plus" /> Manage
                              Groups
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Group List */}
                <div className="table-responsive custom-table">
                  <Table
                    dataSource={usersData}
                    columns={columns}
                    loading={userLoading}
                    isSelectable={true}
                  />
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="datatable-length" />
                  </div>
                  <div className="col-md-6">
                    <div className="datatable-paginate" />
                  </div>
                </div>
                {/* /Group List */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddUserModal />
      <AddUserSuccessModal />
      <RemoveUserModal />
    </>
  );
};

export default ManageUsers;
