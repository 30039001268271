import React, { useState } from "react";
import ImageWithBasePath from "../../core/common/imageWithBasePath";
import { Link, useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert } from "antd";
import { useDispatch } from "react-redux";
import { getErrorMessage } from "../../utils/helper";
import { resetPasswordConfirm } from "../../core/data/redux/auth/thunk";

const ResetPassword = () => {
  const route = all_routes;

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  let { uuid, token } = useParams();

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className="account-content">
      <div className="login-wrapper account-bg reset-bg">
        <div className="login-content">
          <Formik
            initialValues={{
              password: "",
              newPassword: "",
              confirmNewPassword: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().max(255).required("Password is required"),
              newPassword: Yup.string()
                .max(255)
                .required("Password is required"),
              confirmNewPassword: Yup.string()
                .required("Confirm Password is required")
                .test(
                  "confirmPassword",
                  "New Password and Confirm Password must be match!",
                  (confirmNewPassword, yup) =>
                    yup.parent.newPassword === confirmNewPassword
                ),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const { confirmNewPassword, newPassword } = values;
                const res = await dispatch(
                  resetPasswordConfirm({
                    confirmPassword: confirmNewPassword,
                    newPassword,
                    uuid,
                    token,
                  })
                );

                if (res?.error) {
                  setErrors({ submit: getErrorMessage(res?.error) });
                } else {
                  navigate("/login");
                }
              } catch (err) {
                setErrors({ submit: getErrorMessage(err) });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="login-user-info">
                  <div className="login-logo">
                    <ImageWithBasePath
                      src="assets/img/logo.png"
                      className="img-fluid"
                      alt="Logo"
                    />
                  </div>
                  <div className="login-heading">
                    <h4>Reset Password?</h4>
                    <p>
                      Enter New Password &amp; Confirm Password to get inside
                    </p>
                  </div>
                  <div className="form-wrap">
                    <label className="col-form-label">Old Password</label>
                    <div className="pass-group">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        className="pass-input form-control"
                        name="password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <span
                        className={`ti toggle-password ${
                          isPasswordVisible ? "ti-eye" : "ti-eye-off"
                        }`}
                        onClick={togglePasswordVisibility}
                      ></span>
                    </div>
                  </div>
                  {touched.password && errors.password && (
                    <div className="form-wrap">
                      <Alert message={errors.password} type="error" />
                    </div>
                  )}
                  <div className="form-wrap">
                    <label className="col-form-label">New Password</label>
                    <div className="pass-group">
                      <input
                        type={isNewPasswordVisible ? "text" : "password"}
                        className="pass-inputs form-control"
                        name="newPassword"
                        value={values.newPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <span
                        className={`ti toggle-password ${
                          isNewPasswordVisible ? "ti-eye" : "ti-eye-off"
                        }`}
                        onClick={toggleNewPasswordVisibility}
                      ></span>
                    </div>
                  </div>
                  {touched.newPassword && errors.newPassword && (
                    <div className="form-wrap">
                      <Alert message={errors.newPassword} type="error" />
                    </div>
                  )}
                  <div className="form-wrap">
                    <label className="col-form-label">
                      New Confirm Password
                    </label>
                    <div className="pass-group">
                      <input
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        className="pass-input-new form-control"
                        name="confirmNewPassword"
                        value={values.confirmNewPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <span
                        className={`ti toggle-password ${
                          isConfirmPasswordVisible ? "ti-eye" : "ti-eye-off"
                        }`}
                        onClick={toggleConfirmPasswordVisibility}
                      ></span>
                    </div>
                  </div>
                  {touched.confirmNewPassword && errors.confirmNewPassword && (
                    <div className="form-wrap">
                      <Alert message={errors.confirmNewPassword} type="error" />
                    </div>
                  )}
                  {errors.submit && (
                    <div className="form-wrap">
                      <Alert message={errors.submit} type="error" />
                    </div>
                  )}
                  <div className="form-wrap">
                    <button type="submit" className="btn btn-primary">
                      Change Password
                    </button>
                  </div>
                  <div className="login-form text-center">
                    <h6>
                      Return to{" "}
                      <Link to={route.login} className="hover-a">
                        Log In
                      </Link>
                    </h6>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
