import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Table from "../../../core/common/dataTable/index";
import { useDispatch, useSelector } from "react-redux";
import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import AddMenuModal from "../../../core/modals/menus/menu/add_menu_modal";
import AddMenuSuccessModal from "../../../core/modals/menus/menu/add_menu_success_modal";
import { Flex, Tag } from "antd";
import {
  setEditingMenu,
  setRemoveMenu,
} from "../../../core/data/redux/menu/menu";
import RemoveMenuModal from "../../../core/modals/menus/menu/remove_menu_modal";
import { findParents } from "../../../utils/helper";
import { editMenu, getMenus } from "../../../core/data/redux/menu/thunk";
import TableauWorkbooks from "./tableau";
import ExternalLink from "./external";
import { setResetPage } from "../../../core/data/redux/common";

const AppConnectivity = () => {
  const route = all_routes;

  const menuData = useSelector((state: any) => state.menu.menus?.list) || [];
  const groupData = useSelector((state: any) => state.menu.groups?.list) || [];

  const dispatch = useDispatch<any>();
  let { menuId } = useParams();

  const [record, setRecord] = useState<any>();

  const [parentsList, setParents] = useState<any[]>([]);

  // Call initializeStarsState once when the component mounts
  useEffect(() => {
    if (menuData?.length) {
      const record = menuData.find((menu: any) => menu.id === menuId);
      setRecord(record);
    }
  }, [menuId, JSON.stringify(menuData)]);

  useEffect(() => {
    if (record) {
      const parents = findParents(record, groupData, menuData);
      setParents(parents.reverse());
    }
  }, [JSON.stringify(record)]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper d-flex flex-column">
        <div className="content d-flex flex-column" style={{ flex: 1 }}>
          <div className="row h-100 flex-column" style={{ flex: 1 }}>
            <div className="col-md-12">
              {/* Contact User */}
              <div className="page-header contact-head">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <ul className="contact-breadcrumb">
                      {parentsList.map((parent: any, index: number) => (
                        <li key={parent.data.id}>
                          <Link
                            to={
                              parent.is_group
                                ? "#"
                                : parent.data.url
                                ? route.appConnectivity.replace(
                                    ":menuId",
                                    parent.data.id
                                  )
                                : "#"
                            }
                          >
                            {index === 0 ? (
                              <i className="ti ti-arrow-narrow-left" />
                            ) : null}
                            {parent.data?.name}
                          </Link>
                        </li>
                      ))}
                      <li>{record?.name}</li>
                    </ul>
                  </div>
                  <div className="col-sm-6 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onNewTab={() => {
                          if (
                            record &&
                            record?.menu_page_category === "External Page"
                          ) {
                            const menuContent = window.open(
                              record?.url,
                              "_blank"
                            );
                            menuContent?.focus();
                            return;
                          }
                        }}
                        openNewTabButtonAvailable={
                          record &&
                          record?.menu_page_category === "External Page"
                        }
                        onRefresh={() => {
                          dispatch(setResetPage(true));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="col-md-12 d-flex flex-column" style={{ flex: 1 }}>
              <div className="card main-card" style={{ flex: 1 }}>
                <div className="card-body d-flex flex-column">
                  {record ? (
                    <div className="row h-100" style={{ flex: 1 }}>
                      <div className="col-md-12 d-flex flex-column">
                        {record?.menu_page_category === "Tableau Page" ? (
                          <TableauWorkbooks
                            id={menuId as string}
                            item={record}
                          />
                        ) : (
                          <ExternalLink url={record?.url} item={record} />
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppConnectivity;
