import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setResetPage } from "../../../core/data/redux/common";
import { Link } from "react-router-dom";

interface ExternalLinkProps {
  url: string;
  item: any;
}

const ExternalLink = ({ url, item }: ExternalLinkProps) => {
  const [loadingIframe, setLoadingIframe] = useState(true);
  const iframeRef = useRef<any>(null);
  const resetPage = useSelector((state: any) => state.common.reset) || false;

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (resetPage) {
      dispatch(setResetPage(false));
    }
  }, [dispatch, resetPage]);

  useEffect(() => {
    const checkIframeStatus = () => {
      const iframeElement = iframeRef.current;
      if (iframeElement && iframeElement.contentWindow) {
        try {
          const iframeLocation = iframeElement.contentWindow?.location?.href;
          if (iframeLocation === "about:blank") {
            setLoadingIframe(false);
          }
        } catch (e) {
          setLoadingIframe(false);
        }
      }
    };

    const timeout = setTimeout(() => {
      if (loadingIframe) {
        checkIframeStatus();
      }
    }, 10000);

    return () => clearTimeout(timeout);
  }, [loadingIframe]);

  return (
    <div className="row" style={{ flex: 1 }}>
      {!resetPage ? (
        <div className="col-md-12 d-flex flex-column">
          {item?.menu_page_category === "New Tab Message Page" ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                border: "2px solid #ccc",
                borderRadius: "10px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                margin: "20px",
                backgroundColor: "#f4f4f4",
                flex: 1,
              }}
            >
              <div className="d-flex flex-column">
                <div className="fs-3 text-center">{item?.name}</div>
                <div className="fs-5 text-center">
                  <Link to={item?.url ?? "#"} target="_blank">
                    <i className="ti-new-window" />
                    &nbsp;Click here to open in new tab.
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <>
              <iframe
                src={url}
                className="custom-iframe"
                ref={iframeRef}
                style={{
                  border: "2px solid #ccc",
                  borderRadius: "10px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  margin: "20px",
                  backgroundColor: "#f4f4f4",
                  flex: 1,
                }}
              />
              {loadingIframe ? <p> Loading iframe</p> : null}
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ExternalLink;
