import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { setExpandMenu } from "../../data/redux/common";
import { getFlattenSideBarData, getSideBarData } from "../../../utils/helper";
import _ from "lodash";
import "../../../style/icon/themify/themify.css";
import { setViewMenu } from "../../data/redux/menu/menu";

const Sidebar = () => {
  const Location = useLocation();
  const expandMenu = useSelector((state: any) => state.common.expandMenu);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [subOpen, setSubopen] = useState<any>("");
  const [subsidebar, setSubsidebar] = useState("");
  const [sidebarData, setSidebarMenuData] = useState<any[]>([]);

  const menuData = useSelector((state: any) => state.menu.menus?.list) || [];
  const groupData = useSelector((state: any) => state.menu.groups?.list) || [];

  const [searchInputValue, setInputValue] = useState("");
  const [searchItems, setSearchItems] = useState([]);

  const handleInputChange = (value: string) => {
    if (value && value?.length > 2) {
      const result = menuData?.filter(
        (item: any) =>
          item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
      );
      setSearchItems(result);
    } else {
      setSearchItems([]);
    }
  };

  const debouncedChangeHandler = useCallback(
    _.debounce(handleInputChange, 500),
    []
  );

  const handleChange = (event: any) => {
    debouncedChangeHandler(event.target.value);
    setInputValue(event.target.value);
  };

  useEffect(() => {}, [searchInputValue]);

  useEffect(() => {
    if (searchItems?.length) {
      const searchMenuItems = getFlattenSideBarData(searchItems);
      setSidebarMenuData(searchMenuItems);
    } else {
      const data = getSideBarData(groupData, menuData);
      setSidebarMenuData(data);
    }
  }, [JSON.stringify(searchItems)]);

  useEffect(() => {
    if (groupData?.length && menuData?.length) {
      const data = getSideBarData(groupData, menuData);
      setSidebarMenuData(data);
    }
  }, [JSON.stringify(groupData), JSON.stringify(menuData)]);

  const toggleSidebar = (title: any) => {
    localStorage.setItem("menuOpened", title);
    if (title === subOpen) {
      setSubopen("");
    } else {
      setSubopen(title);
    }
  };

  const toggleSubsidebar = (subitem: any) => {
    if (subitem === subsidebar) {
      setSubsidebar("");
    } else {
      setSubsidebar(subitem);
    }
  };
  const toggle = () => {
    dispatch(setExpandMenu(true));
  };
  const toggle2 = () => {
    dispatch(setExpandMenu(false));
  };

  useEffect(() => {
    setSubopen(localStorage.getItem("menuOpened"));
    // Select all 'submenu' elements
    const submenus = document.querySelectorAll(".submenu");
    // Loop through each 'submenu'
    submenus.forEach((submenu) => {
      // Find all 'li' elements within the 'submenu'
      const listItems = submenu.querySelectorAll("li");
      submenu.classList.remove("active");
      // Check if any 'li' has the 'active' class
      listItems.forEach((item) => {
        if (item.classList.contains("active")) {
          // Add 'active' class to the 'submenu'
          submenu.classList.add("active");
          return;
        }
      });
    });
  }, [Location.pathname]);

  return (
    <>
      <div
        className="sidebar flex-column"
        id="sidebar"
        onMouseEnter={toggle}
        onMouseLeave={toggle2}
      >
        <div className="p-2">
          <div className="form-wrap icon-form mb-2">
            <span className="form-icon">
              <i className="ti ti-search" />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search Menus"
              value={searchInputValue}
              onChange={handleChange}
            />
          </div>
        </div>
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                {sidebarData?.map((mainLabel, index) => (
                  <li className="clinicdropdown" key={index}>
                    <h6 className="submenu-hdr">{mainLabel?.label}</h6>
                    <ul>
                      {mainLabel?.submenuItems?.map((title: any) => {
                        let link_array: any = [];
                        if ("submenuItems" in title) {
                          title.submenuItems?.forEach((link: any) => {
                            link_array.push(link?.link);
                            if (link?.submenu && "submenuItems" in link) {
                              link.submenuItems?.forEach((item: any) => {
                                link_array.push(item?.link);
                              });
                            }
                          });
                        }
                        title.links = link_array;

                        return (
                          <li className="submenu" key={title.id}>
                            <Link
                              to={title?.link ? title?.link : "#"}
                              className={`${
                                subOpen === title?.id ? "subdrop" : ""
                              } ${
                                title?.links?.includes(Location.pathname)
                                  ? "active"
                                  : ""
                              } ${
                                title?.submenuItems
                                  ?.map((link: any) => link?.link)
                                  .includes(Location.pathname) ||
                                title?.link === Location.pathname
                                  ? "active"
                                  : title?.subLink1 === Location.pathname
                                  ? "active"
                                  : title?.subLink2 === Location.pathname
                                  ? "active"
                                  : title?.subLink3 === Location.pathname
                                  ? "active"
                                  : title?.subLink4 === Location.pathname
                                  ? "active"
                                  : ""
                              }`}
                              onClick={(event) => {
                                toggleSidebar(title?.id);
                                event.preventDefault();
                                if (title?.link) {
                                  navigate(title?.link);
                                  setViewMenu(title);
                                }
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <i className={title.icon}></i>
                                <span>{title?.label}</span>
                              </div>
                              <span
                                className={title?.submenu ? "menu-arrow" : ""}
                              />
                            </Link>
                            <ul
                              style={{
                                display:
                                  subOpen === title?.id ? "block" : "none",
                              }}
                            >
                              {title?.submenuItems?.map((item: any) => (
                                <li
                                  className="submenu submenu-two"
                                  key={item.id}
                                >
                                  <Link
                                    to={item?.link ? item?.link : "#"}
                                    className={`${
                                      item?.submenuItems
                                        ?.map((link: any) => link?.link)
                                        .includes(Location.pathname) ||
                                      item?.link === Location.pathname
                                        ? "active"
                                        : ""
                                    } ${
                                      subsidebar === item?.id ? "subdrop" : ""
                                    }`}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      toggleSubsidebar(item?.id);
                                      if (item?.link) {
                                        navigate(item?.link);
                                        setViewMenu(item);
                                      }
                                    }}
                                  >
                                    <div
                                      className="d-flex align-items-center gap-2"
                                      onClick={() => {}}
                                    >
                                      {item.icon ? (
                                        <i className={item.icon}></i>
                                      ) : null}
                                      {item?.label}
                                    </div>
                                    <span
                                      className={
                                        item?.submenu ? "menu-arrow" : ""
                                      }
                                    />
                                  </Link>
                                  <ul
                                    style={{
                                      display:
                                        subsidebar === item?.id
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {item?.submenuItems?.map((items: any) => (
                                      <li key={items.id}>
                                        <Link
                                          to={items?.link ? items?.link : "#"}
                                          className={`${
                                            subsidebar === items?.id
                                              ? "submenu-two subdrop"
                                              : "submenu-two"
                                          } ${
                                            items?.submenuItems
                                              ?.map((link: any) => link.link)
                                              .includes(Location.pathname) ||
                                            items?.link === Location.pathname
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={(event) => {
                                            event.preventDefault();

                                            if (items?.link) {
                                              navigate(items?.link);
                                              setViewMenu(item);
                                            }
                                          }}
                                        >
                                          <div
                                            className="d-flex align-items-center gap-2"
                                            onClick={() => {}}
                                          >
                                            {items.icon ? (
                                              <i className={items.icon}></i>
                                            ) : null}
                                            {items?.label}
                                          </div>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default Sidebar;
