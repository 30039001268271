import axios from "../utils/axios";

class CampaignService {
  // Campaign Actions
  async getCampaigns() {
    try {
      const response = await axios.get("/api/contact/campaigns/");
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async createNewCampaign(payload) {
    try {
      const response = await axios.post("/api/contact/campaigns/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async getCampaign(id) {
    try {
      await axios.get(`/api/contact/campaigns/${id}/`);
      return id;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async editCampaign(payload) {
    const { id, data } = payload;
    try {
      const response = await axios.put(`/api/contact/campaigns/${id}/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async removeCampaign(id) {
    try {
      await axios.delete(`/api/contact/campaigns/${id}/`);
      return id;
    } catch (err) {
      return null;
    }
  }
}

export default new CampaignService();
