import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import pkg from "lodash";
import { Alert } from "antd";
import { getErrorMessage } from "../../../../utils/helper";
import { setEditingCampaignClose } from "../../../data/redux/campaign/campaign";
import { v4 as uuidv4 } from "uuid";
import "./index.scss";
import {
  createCampaign,
  editCampaign,
  getCampaigns,
} from "../../../data/redux/campaign/thunk";

const { isEmpty, uniqWith } = pkg;

interface AddCampaignModalProps {
  menuId?: string;
}

const AddCampaignModal = ({ menuId }: AddCampaignModalProps) => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.campaign?.campaigns?.edit?.loading
  );

  const editingData = useSelector(
    (state: any) => state?.campaign?.campaigns?.edit?.data
  );
  const menuData = useSelector((state: any) => state.menu.menus?.list) || [];
  const [campaignInfoValues, setCampaignInfoValues] = useState<{
    [key: string]: {
      id: string;
      label: string;
      value: string;
    };
  }>({});

  const [isEditingCampaignInfo, setIsEditingCampaignInfo] = useState<{
    [key: string]: boolean;
  }>({});

  const [editingCampaignInfo, setEditingCampaignInfo] = useState<{
    [key: string]: {
      id: string;
      label: string;
      value: string;
    };
  }>({});

  useEffect(() => {
    if (!isEmpty(editingData)) {
      const campaignInfoValues = (editingData?.["campaign_info"] || [])?.reduce(
        (result: any, currentField: any) => {
          let nextResult = { ...result };
          const id = uuidv4();
          if (currentField.value) {
            nextResult = {
              ...nextResult,
              [id]: {
                label: currentField.label,
                value: currentField.value,
              },
            };
          }
          return nextResult;
        },
        {}
      );
      setCampaignInfoValues(campaignInfoValues);
      setEditingCampaignInfo(campaignInfoValues);
    }
  }, [JSON.stringify(editingData)]);

  const formik = useFormik({
    initialValues: {
      title: editingData?.title || "",
      status: editingData ? editingData.status : "active",
      campaign_info: editingData ? editingData.campaign_info : [],
      assigned_user_groups:
        editingData?.assigned_user_groups?.map((user: any) => user.id) || [],
      submit: null,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().max(255).required("TItle is required"),
      assigned_user_groups: Yup.array(),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        let newCampaign: any = values;

        if (!isEmpty(campaignInfoValues)) {
          newCampaign["campaign_info"] = Object.values(campaignInfoValues).map(
            (infoField) => ({
              label: infoField?.label,
              value: infoField?.value,
            })
          );
        } else {
          newCampaign["campaign_info"] = [];
        }

        newCampaign.assigned_user_groups_ids =
          values?.assigned_user_groups || [];
        newCampaign.assigned_user_groups =
          userOptions?.assigned_user_groups?.filter((user: any) =>
            ((values?.assigned_user_groups || []) as any[]).includes(user.id)
          );

        let res: any;
        if (editingData) {
          res = await dispatch(
            editCampaign({ id: editingData.campaign_id, data: newCampaign })
          );
        } else {
          res = await dispatch(createCampaign(newCampaign));
        }

        if (res && res?.error) {
          let errorData;
          try {
            errorData = JSON.parse(res?.error?.message);
            setErrors(errorData);
          } catch (_) {
            errorData = "";
            setErrors({ submit: getErrorMessage(errorData) });
          }
        } else {
          resetForm();
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
  } = formik;

  const userOptions = useMemo(() => {
    const campaignPageData = menuData?.find((menu: any) => menu?.id === menuId);
    if (campaignPageData) {
      const options = campaignPageData?.assigned_user_groups.map(
        (group: any) => ({
          label: group.name,
          value: group.id,
        })
      );
      return options;
    }
    return [];
  }, [menuId, JSON.stringify(menuData)]);

  const onCloseModal = () => {
    dispatch(setEditingCampaignClose());
  };

  const addNewInfoField = () => {
    const id = uuidv4();

    setEditingCampaignInfo((prev: any) => ({
      ...prev,
      [id]: {
        label: "",
        value: "",
      },
    }));
    setIsEditingCampaignInfo((prev: any) => ({
      ...prev,
      [id]: true,
    }));
  };

  return (
    <FormikProvider value={formik}>
      {/* Add/Edit Campaign */}
      <div
        className={modalToggle ? "toggle-popup sidebar-popup" : "toggle-popup"}
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>{editingData ? "Edit Campaign" : "Add New Campaign"}</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => onCloseModal()}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {/* Campaign Info */}

                <div className="row gy-2">
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Campaign Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        value={values.title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.title && errors.title && (
                      <Alert message={errors.title as string} type="error" />
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Assigned Groups <span className="text-danger">*</span>
                      </label>
                      <Select
                        defaultValue={[]}
                        isMulti
                        name="assigned_user_groups"
                        options={userOptions}
                        onBlur={handleBlur}
                        value={
                          userOptions
                            ? userOptions.filter((option: any) =>
                                (
                                  (values.assigned_user_groups || []) as any[]
                                ).includes(option.value)
                              )
                            : []
                        }
                        onChange={(options: any) => {
                          setFieldValue(
                            "assigned_user_groups",
                            (options || []).map((option: any) => option.value)
                          );
                        }}
                      />
                    </div>
                    {touched.assigned_user_groups &&
                      errors.assigned_user_groups && (
                        <Alert
                          message={errors.assigned_user_groups as string}
                          type="error"
                        />
                      )}
                  </div>
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">Status</label>
                      <input
                        type="text"
                        name="status"
                        className="form-control"
                        value={values.status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.status && errors.status && (
                      <Alert message={errors.status as string} type="error" />
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="row gap-2">
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => addNewInfoField()}
                        >
                          Add Campaign Info
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div>Campaign Info</div>
                      </div>
                      <div className="col-md-12">
                        <div className="row gy-2">
                          {Object.entries(editingCampaignInfo).map(
                            ([fieldId, fieldValue]) => {
                              const { label, value } = fieldValue || {};
                              return (
                                <div className="col-md-12" key={fieldId}>
                                  {isEditingCampaignInfo?.[fieldId] ? (
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="d-flex">
                                          <div className="w-100 mb-2">
                                            <label className="col-form-label">
                                              Field Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Field Name"
                                              value={label}
                                              onChange={(e) => {
                                                const targetValue =
                                                  e.target.value;

                                                setEditingCampaignInfo(
                                                  (prev: any) => ({
                                                    ...prev,
                                                    [fieldId]: {
                                                      ...(prev?.[fieldId] ||
                                                        {}),
                                                      label: targetValue,
                                                    },
                                                  })
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="d-flex">
                                          <div className="w-100">
                                            <label className="col-form-label">
                                              Field Value
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Field Value"
                                              value={value}
                                              onChange={(e) => {
                                                const targetValue =
                                                  e.target.value;
                                                setEditingCampaignInfo(
                                                  (prev: any) => ({
                                                    ...prev,
                                                    [fieldId]: {
                                                      ...(prev?.[fieldId] ||
                                                        {}),
                                                      value: targetValue,
                                                    },
                                                  })
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="d-flex justify-content-end mb-2">
                                          <button
                                            type="button"
                                            className="btn btn-light"
                                            onClick={() => {
                                              setCampaignInfoValues((prev) => {
                                                const nextResult = {
                                                  ...prev,
                                                  [fieldId]:
                                                    editingCampaignInfo[
                                                      fieldId
                                                    ],
                                                };

                                                return nextResult;
                                              });

                                              setIsEditingCampaignInfo(
                                                (prev) => ({
                                                  ...prev,
                                                  [fieldId]: false,
                                                })
                                              );
                                            }}
                                          >
                                            <span className="action-icon">
                                              <i className="fa fa-check" />
                                            </span>
                                          </button>

                                          <button
                                            type="button"
                                            className="btn btn-light"
                                            style={{
                                              marginLeft: "8px",
                                            }}
                                            onClick={() => {
                                              setEditingCampaignInfo((prev) => {
                                                const nextResult = {
                                                  ...prev,
                                                  [fieldId]:
                                                    campaignInfoValues[fieldId],
                                                };

                                                return nextResult;
                                              });

                                              setIsEditingCampaignInfo(
                                                (prev) => ({
                                                  ...prev,
                                                  [fieldId]: false,
                                                })
                                              );
                                            }}
                                          >
                                            <span className="action-icon">
                                              <i className="fa fa-x" />
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="d-flex mb-2 campaign-info-item">
                                          <label
                                            className={`${
                                              label
                                                ? "col-form-label"
                                                : "text-muted"
                                            } text-nowrap`}
                                          >
                                            {label || "No Field Name"}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Field Value"
                                            value={value}
                                            disabled
                                          />

                                          <button
                                            type="button"
                                            className="btn btn-light"
                                            onClick={() => {
                                              setIsEditingCampaignInfo(
                                                (prev) => ({
                                                  ...prev,
                                                  [fieldId]: true,
                                                })
                                              );
                                            }}
                                          >
                                            <span className="action-icon">
                                              <i className="ti-pencil" />
                                            </span>
                                          </button>

                                          <button
                                            type="button"
                                            className="btn btn-light"
                                            onClick={() => {
                                              setEditingCampaignInfo((prev) => {
                                                const nextResult = {
                                                  ...prev,
                                                };
                                                delete nextResult[fieldId];

                                                return nextResult;
                                              });

                                              setCampaignInfoValues((prev) => {
                                                const nextResult = {
                                                  ...prev,
                                                };
                                                delete nextResult[fieldId];

                                                return nextResult;
                                              });
                                            }}
                                          >
                                            <span className="action-icon">
                                              <i className="ti ti-trash-x" />
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="submit-button text-end">
                  <Link
                    to="#"
                    className="btn btn-light sidebar-close"
                    onClick={() => onCloseModal()}
                  >
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    {editingData ? "Edit" : "Create"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add/Edit Group */}
    </FormikProvider>
  );
};

export default AddCampaignModal;
