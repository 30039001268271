import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import pkg from "lodash";
import { Alert } from "antd";
import { setEditingUserGroupClose } from "../../../data/redux/auth/auth";
import { createUserGroup, editUserGroup } from "../../../data/redux/auth/thunk";
import Select from "react-select";

const { mapValues, merge, pick } = pkg;

const getInitialValues = (userGroup: any) => {
  let newUserGroup: {
    name: string;
    members: any[];
    permissions: any[];
  } = {
    name: "",
    members: [],
    permissions: [],
  };

  if (userGroup) {
    // Replace null values with empty strings
    const sanitizedUserGroup = mapValues(userGroup, (value) =>
      value === null ? "" : value
    );
    newUserGroup = merge({}, newUserGroup, sanitizedUserGroup);

    if (newUserGroup.members) {
      newUserGroup.members = (newUserGroup.members || []).map(
        (member: any) => member.id
      );
    }

    if (newUserGroup.permissions) {
      newUserGroup.permissions = (newUserGroup.permissions || []).map(
        (permission: any) => permission.id
      );
    }
  }

  return pick(newUserGroup, ["name", "members", "permissions"]);
};

const AddUserGroupModal = () => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.auth?.groups?.edit?.loading
  );
  const editingData = useSelector(
    (state: any) => state?.auth?.groups?.edit?.data
  );

  const usersData = useSelector((state: any) => state?.auth?.users?.data);
  const permissionData = useSelector(
    (state: any) => state?.auth?.permissions?.data
  );

  const memberOptions = useMemo(() => {
    const options = (usersData || []).map((user: any) => ({
      label: user.username,
      value: user.id,
    }));

    return options;
  }, [JSON.stringify(usersData)]);

  const permissionOptions = useMemo(() => {
    const options = (permissionData || []).map((permission: any) => ({
      label: permission.name,
      value: permission.id,
    }));

    return options;
  }, [JSON.stringify(permissionData)]);

  const formik = useFormik({
    initialValues: getInitialValues(editingData),
    validationSchema: Yup.object().shape({
      name: Yup.string().max(255).required("Name is required"),
      members: Yup.array(),
      permissions: Yup.array(),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        const newUserGroup: any = values;

        newUserGroup.members_ids = values?.members || [];
        newUserGroup.members = usersData?.filter((user: any) =>
          ((values?.members || []) as any[]).includes(user.id)
        );

        newUserGroup.permissions_ids = values?.permissions || [];
        newUserGroup.permissions = permissionData?.filter((permission: any) =>
          ((values?.permissions || []) as any[]).includes(permission.id)
        );

        if (editingData) {
          await dispatch(
            editUserGroup({ id: editingData.id, data: newUserGroup })
          );
        } else {
          await dispatch(createUserGroup(newUserGroup));
        }
        resetForm();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
  } = formik;

  const onCloseModal = () => {
    dispatch(setEditingUserGroupClose());
  };

  return (
    <FormikProvider value={formik}>
      {/* Add/Edit User Group */}
      <div
        className={modalToggle ? "toggle-popup sidebar-popup" : "toggle-popup"}
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>{editingData ? "Edit User Group" : "Add New User Group"}</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => onCloseModal()}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {/* User Group Info */}

                <div className="row gy-2">
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.name && errors.name && (
                      <Alert message={errors.name as string} type="error" />
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Members <span className="text-danger">*</span>
                      </label>
                      <Select
                        defaultValue={[]}
                        isMulti
                        name="members"
                        options={memberOptions}
                        onBlur={handleBlur}
                        value={
                          memberOptions
                            ? memberOptions.filter((option: any) =>
                                ((values.members || []) as any[]).includes(
                                  option.value
                                )
                              )
                            : []
                        }
                        onChange={(options: any) => {
                          setFieldValue(
                            "members",
                            (options || []).map((option: any) => option.value)
                          );
                        }}
                      />
                    </div>
                    {touched.members && errors.members && (
                      <Alert message={errors.members as string} type="error" />
                    )}
                  </div>
                </div>

                <div className="submit-button text-end">
                  <Link
                    to="#"
                    className="btn btn-light sidebar-close"
                    onClick={() => onCloseModal()}
                  >
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    {editingData ? "Edit" : "Create"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add/Edit Group */}
    </FormikProvider>
  );
};

export default AddUserGroupModal;
