import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { Alert } from "antd";
import { getErrorMessage } from "../../../../utils/helper";
import { setEditFieldsAdminClose } from "../../../data/redux/fieldsAdmin/fieldsAdmin";
import {
  createAdminField,
  editAdminField,
} from "../../../data/redux/fieldsAdmin/thunk";

const AddFieldAdminModal = () => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.fieldsAdmin.admin?.edit?.loading
  );

  const editingData = useSelector(
    (state: any) => state?.fieldsAdmin.admin?.edit?.data
  );
  const availableUserGroupsData =
    useSelector((state: any) => state?.auth?.groups?.data) || [];
  const userGroupOptions = useMemo(() => {
    if (availableUserGroupsData?.length) {
      const options = availableUserGroupsData.map((group: any) => ({
        label: group.name,
        value: group.id,
      }));
      return options;
    }
    return [];
  }, [JSON.stringify(availableUserGroupsData)]);

  const FILTER_TYPES = [
    {
      label: "Search",
      value: "search",
    },
    {
      label: "Multi Select Dropdown",
      value: "multi-select",
    },
  ];
  const DISPLAY_FIELD_TYPES = [
    {
      label: "Char",
      value: "char",
    },
    {
      label: "Text",
      value: "text",
    },
    {
      label: "Datetime",
      value: "datetime",
    },
    {
      label: "Boolean",
      value: "boolean",
    },
    {
      label: "Number",
      value: "int",
    },
    {
      label: "Decimal",
      value: "decimal",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "Email With Icon",
      value: "email-with-icon",
    },
    {
      label: "Phone With Icon",
      value: "phone-with-icon",
    },
  ];

  return (
    <>
      {/* Add/Edit Field Admin */}
      <div
        className={modalToggle ? "toggle-popup sidebar-popup" : "toggle-popup"}
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>{editingData ? "Edit Field Admin" : "Add New Field Admin"}</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => dispatch(setEditFieldsAdminClose())}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <Formik
                initialValues={{
                  original_name: editingData ? editingData.original_name : "",
                  original_field_type: editingData
                    ? editingData.original_field_type
                    : "",
                  display_name: editingData ? editingData.display_name : "",
                  display_field_type: editingData
                    ? editingData.display_field_type
                    : "",
                  is_filter: editingData ? editingData.is_filter : false,
                  is_required: editingData ? editingData.is_required : false,
                  is_show: editingData ? editingData.is_show : false,
                  is_show_contact: editingData
                    ? editingData.is_show_contact
                    : true,
                  is_show_internal: editingData
                    ? editingData?.is_show_internal
                    : true,
                  filter_type: editingData ? editingData.filter_type : "search",
                  width: editingData ? editingData.width : 100,
                  assigned_user_groups:
                    editingData?.assigned_user_groups?.map(
                      (user: any) => user.id
                    ) || [],
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  display_name: Yup.string()
                    .max(255)
                    .required("Display Name is required"),
                  display_field_type: Yup.string()
                    .max(255)
                    .required("Display Type is required"),
                  width: Yup.number().required("Width is required."),
                })}
                enableReinitialize={true}
                onSubmit={async (values, { resetForm, setErrors }) => {
                  try {
                    let newFieldAdmin: any = values;
                    newFieldAdmin.assigned_user_groups_ids =
                      values?.assigned_user_groups || [];
                    newFieldAdmin.assigned_user_groups =
                      availableUserGroupsData.filter((user: any) =>
                        (
                          (values?.assigned_user_groups || []) as any[]
                        ).includes(user.id)
                      );
                    let res: any;
                    if (editingData) {
                      res = await dispatch(
                        editAdminField({
                          id: editingData.id,
                          data: newFieldAdmin,
                        })
                      );
                    } else {
                      res = await dispatch(createAdminField(newFieldAdmin));
                    }

                    if (res && res?.error) {
                      let errorData;
                      try {
                        errorData = JSON.parse(res?.error?.message);
                        setErrors(errorData);
                      } catch (_) {
                        errorData = "";
                        setErrors({ submit: getErrorMessage(errorData) });
                      }
                    } else {
                      resetForm();
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    {/* Field Admin Info */}

                    <div className="row gy-2">
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Original Field Name{" "}
                          </label>
                          <input
                            type="text"
                            name="original_name"
                            className="form-control"
                            value={values.original_name}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Original Field Type{" "}
                          </label>
                          <input
                            type="text"
                            name="original_field_type"
                            className="form-control"
                            value={values.original_field_type}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Display Name{" "}
                          </label>
                          <input
                            type="text"
                            name="display_name"
                            className="form-control"
                            value={values.display_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        {touched.display_name && errors.display_name && (
                          <Alert
                            message={errors.display_name as string}
                            type="error"
                          />
                        )}
                      </div>
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Original Field Type{" "}
                          </label>
                          <Select
                            defaultValue={[]}
                            name="display_field_type"
                            options={DISPLAY_FIELD_TYPES}
                            onBlur={handleBlur}
                            value={
                              DISPLAY_FIELD_TYPES
                                ? DISPLAY_FIELD_TYPES.find(
                                    (option: any) =>
                                      values.display_field_type === option.value
                                  )
                                : []
                            }
                            onChange={(option: any) => {
                              setFieldValue("display_field_type", option.value);
                            }}
                          />
                        </div>
                        {touched.display_field_type &&
                          errors.display_field_type && (
                            <Alert
                              message={errors.display_field_type as string}
                              type="error"
                            />
                          )}
                      </div>
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">Is Shown </label>
                          <div className="form-switch form-check-md">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={values.is_show}
                              name="is_show"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {touched.is_show && errors.is_show && (
                          <Alert
                            message={errors.is_show as string}
                            type="error"
                          />
                        )}
                      </div>
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Is Shown Internal{" "}
                          </label>
                          <div className="form-switch form-check-md">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={values.is_show_internal}
                              name="is_show_internal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {touched.is_show_internal &&
                          errors.is_show_internal && (
                            <Alert
                              message={errors.is_show_internal as string}
                              type="error"
                            />
                          )}
                      </div>
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">Is Required </label>
                          <div className="form-switch form-check-md">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={values.is_required}
                              name="is_required"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {touched.is_required && errors.is_required && (
                          <Alert
                            message={errors.is_required as string}
                            type="error"
                          />
                        )}
                      </div>
                      {values?.is_show_contact && (
                        <div className="col-md-12">
                          <div className="form-wrap">
                            <label className="col-form-label">Is Filter </label>
                            <div className="form-switch form-check-md">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={values.is_filter}
                                name="is_filter"
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {touched.is_filter && errors.is_filter && (
                            <Alert
                              message={errors.is_filter as string}
                              type="error"
                            />
                          )}
                        </div>
                      )}
                      {values?.is_filter && (
                        <div className="col-md-12">
                          <div className="form-wrap">
                            <label className="col-form-label">
                              Filter Type{" "}
                            </label>
                            <Select
                              defaultValue={[]}
                              name="filter_type"
                              options={FILTER_TYPES}
                              onBlur={handleBlur}
                              value={FILTER_TYPES.find(
                                (type: any) => type.value === values.filter_type
                              )}
                              onChange={(option: any) => {
                                setFieldValue("filter_type", option.value);
                              }}
                            />
                          </div>
                          {touched.filter_type && errors.filter_type && (
                            <Alert
                              message={errors.filter_type as string}
                              type="error"
                            />
                          )}
                        </div>
                      )}
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">Width </label>
                          <input
                            type="number"
                            name="width"
                            className="form-control"
                            value={values.width}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        {touched.width && errors.width && (
                          <Alert
                            message={errors.width as string}
                            type="error"
                          />
                        )}
                      </div>
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Assigned Groups{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            defaultValue={[]}
                            isMulti
                            name="assigned_user_groups"
                            options={userGroupOptions}
                            onBlur={handleBlur}
                            value={
                              userGroupOptions
                                ? userGroupOptions.filter((option: any) =>
                                    (
                                      (values.assigned_user_groups ||
                                        []) as any[]
                                    ).includes(option.value)
                                  )
                                : []
                            }
                            onChange={(options: any) => {
                              setFieldValue(
                                "assigned_user_groups",
                                (options || []).map(
                                  (option: any) => option.value
                                )
                              );
                            }}
                          />
                        </div>
                        {touched.assigned_user_groups &&
                          errors.assigned_user_groups && (
                            <Alert
                              message={errors.assigned_user_groups as string}
                              type="error"
                            />
                          )}
                      </div>
                    </div>
                    <div className="row gy-2">
                      <div className="col-md-12">
                        <div className="form-wrap">
                          {errors.submit && (
                            <Alert
                              message={errors.submit as string}
                              type="error"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="submit-button text-end">
                      <Link
                        to="#"
                        className="btn btn-light sidebar-close"
                        onClick={() => dispatch(setEditFieldsAdminClose())}
                      >
                        Cancel
                      </Link>
                      <button type="submit" className="btn btn-primary">
                        {editingData ? "Edit" : "Create"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* /Add/Edit Field Admin */}
    </>
  );
};

export default AddFieldAdminModal;
