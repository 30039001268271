import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { deleteMenu } from "../../../data/redux/menu/thunk";
import { Alert } from "antd";
import { setRemoveMenuClose } from "../../../data/redux/menu/menu";

const RemoveCampaignModal = () => {
  const dispatch = useDispatch<any>();
  const deletingRecordId = useSelector(
    (state: any) => state?.menu?.menus?.delete?.data
  );

  return (
    <>
      {/* Delete Menu */}
      <div
        className={`modal custom-modal fade ${deletingRecordId ? "show" : ""}`}
        style={{ display: deletingRecordId ? "block" : "none" }}
        id="delete_menu"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 m-0 justify-content-end">
              <button
                className="btn-close"
                onClick={() => dispatch(setRemoveMenuClose())}
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="ti ti-trash-x" />
                </div>
                <h3>Remove Menu?</h3>
                <p className="del-info">
                  Are you sure you want to remove menu you selected.
                </p>
                <div className="col-lg-12 text-center modal-btn">
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={() => dispatch(setRemoveMenuClose())}
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-danger"
                    onClick={() => dispatch(deleteMenu(deletingRecordId))}
                  >
                    Yes, Delete it
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Menu */}
    </>
  );
};

export default RemoveCampaignModal;
