import axios from "../utils/axios";

class MenuService {
  // Menu Group Actions

  async getMenuGroups() {
    try {
      const response = await axios.get("/api/menu/menugroups/");
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async getMenuGroup(id) {
    try {
      const response = await axios.get(`/api/menu/menugroups/${id}/`);
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async createNewMenuGroup(payload) {
    try {
      const response = await axios.post("/api/menu/menugroups/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async editMenuGroup(payload) {
    const { id, data } = payload;
    try {
      const response = await axios.put(`/api/menu/menugroups/${id}/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async removeMenuGroup(id) {
    try {
      await axios.delete(`/api/menu/menugroups/${id}/`);
      return id;
    } catch (err) {
      return null;
    }
  }

  // Menu Actions
  async getMenus() {
    try {
      const response = await axios.get("/api/menu/menus/");
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async createNewMenu(payload) {
    try {
      const response = await axios.post("/api/menu/menus/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async getMenu(id) {
    try {
      await axios.get(`/api/menu/menus/${id}/`);
      return id;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async editMenu(payload) {
    const { id, data } = payload;
    try {
      const response = await axios.put(`/api/menu/menus/${id}/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async removeMenu(id) {
    try {
      await axios.delete(`/api/menu/menus/${id}/`);
      return id;
    } catch (err) {
      return null;
    }
  }

  async getJWT(id) {
    try {
      const response = await axios.get(`/api/menu/generate-jwt-token/`);
      return response.data;
    } catch (err) {
      return null;
    }
  }
}

export default new MenuService();
