export const IconsData = [
  "ti-arrow-up",
  "ti-arrow-right",
  "ti-arrow-left",
  "ti-arrow-down",
  "ti-arrows-vertical",
  "ti-arrows-horizontal",
  "ti-angle-up",
  "ti-angle-right",
  "ti-angle-left",
  "ti-angle-down",
  "ti-angle-double-up",
  "ti-angle-double-right",
  "ti-angle-double-left",
  "ti-angle-double-down",
  "ti-move",
  "ti-fullscreen",
  "ti-arrow-top-right",
  "ti-arrow-top-left",
  "ti-arrow-circle-up",
  "ti-arrow-circle-right",
  "ti-arrow-circle-left",
  "ti-arrow-circle-down",
  "ti-arrows-corner",
  "ti-split-v",
  "ti-split-v-alt",
  "ti-split-h",
  "ti-hand-point-up",
  "ti-hand-point-right",
  "ti-hand-point-left",
  "ti-hand-point-down",
  "ti-back-right",
  "ti-back-left",
  "ti-exchange-vertical",
  "ti-wand",
  "ti-save",
  "ti-save-alt",
  "ti-direction",
  "ti-direction-alt",
  "ti-user",
  "ti-link",
  "ti-unlink",
  "ti-trash",
  "ti-target",
  "ti-tag",
  "ti-desktop",
  "ti-tablet",
  "ti-mobile",
  "ti-email",
  "ti-star",
  "ti-spray",
  "ti-signal",
  "ti-shopping-cart",
  "ti-shopping-cart-full",
  "ti-settings",
  "ti-search",
  "ti-zoom-in",
  "ti-zoom-out",
  "ti-cut",
  "ti-ruler",
  "ti-ruler-alt-2",
  "ti-ruler-pencil",
  "ti-ruler-alt",
  "ti-bookmark",
  "ti-bookmark-alt",
  "ti-reload",
  "ti-plus",
  "ti-minus",
  "ti-close",
  "ti-pin",
  "ti-pencil",
  "ti-pencil-alt",
  "ti-paint-roller",
  "ti-paint-bucket",
  "ti-na",
  "ti-medall",
  "ti-medall-alt",
  "ti-marker",
  "ti-marker-alt",
  "ti-lock",
  "ti-unlock",
  "ti-location-arrow",
  "ti-layout",
  "ti-layers",
  "ti-layers-alt",
  "ti-key",
  "ti-image",
  "ti-heart",
  "ti-heart-broken",
  "ti-hand-stop",
  "ti-hand-open",
  "ti-hand-drag",
  "ti-flag",
  "ti-flag-alt",
  "ti-flag-alt-2",
  "ti-eye",
  "ti-import",
  "ti-export",
  "ti-cup",
  "ti-crown",
  "ti-comments",
  "ti-comment",
  "ti-comment-alt",
  "ti-thought",
  "ti-clip",
  "ti-check",
  "ti-check-box",
  "ti-camera",
  "ti-announcement",
  "ti-brush",
  "ti-brush-alt",
  "ti-palette",
  "ti-briefcase",
  "ti-bolt",
  "ti-bolt-alt",
  "ti-blackboard",
  "ti-bag",
  "ti-world",
  "ti-wheelchair",
  "ti-car",
  "ti-truck",
  "ti-timer",
  "ti-ticket",
  "ti-thumb-up",
  "ti-thumb-down",
  "ti-stats-up",
  "ti-stats-down",
  "ti-shine",
  "ti-shift-right",
  "ti-shift-left",
  "ti-shift-right-alt",
  "ti-shift-left-alt",
  "ti-shield",
  "ti-notepad",
  "ti-server",
  "ti-pulse",
  "ti-printer",
  "ti-power-off",
  "ti-plug",
  "ti-pie-chart",
  "ti-panel",
  "ti-package",
  "ti-music",
  "ti-music-alt",
  "ti-mouse",
  "ti-mouse-alt",
  "ti-money",
  "ti-microphone",
  "ti-menu",
  "ti-menu-alt",
  "ti-map",
  "ti-map-alt",
  "ti-location-pin",
  "ti-light-bulb",
  "ti-info",
  "ti-infinite",
  "ti-id-badge",
  "ti-hummer",
  "ti-home",
  "ti-help",
  "ti-headphone",
  "ti-harddrives",
  "ti-harddrive",
  "ti-gift",
  "ti-game",
  "ti-filter",
  "ti-files",
  "ti-file",
  "ti-zip",
  "ti-folder",
  "ti-envelope",
  "ti-dashboard",
  "ti-cloud",
  "ti-cloud-up",
  "ti-cloud-down",
  "ti-clipboard",
  "ti-calendar",
  "ti-book",
  "ti-bell",
  "ti-basketball",
  "ti-bar-chart",
  "ti-bar-chart-alt",
  "ti-archive",
  "ti-anchor",
  "ti-alert",
  "ti-alarm-clock",
  "ti-agenda",
  "ti-write",
  "ti-wallet",
  "ti-video-clapper",
  "ti-video-camera",
  "ti-vector",
  "ti-support",
  "ti-stamp",
  "ti-slice",
  "ti-shortcode",
  "ti-receipt",
  "ti-pin2",
  "ti-pin-alt",
  "ti-pencil-alt2",
  "ti-eraser",
  "ti-more",
  "ti-more-alt",
  "ti-microphone-alt",
  "ti-magnet",
  "ti-line-double",
  "ti-line-dotted",
  "ti-line-dashed",
  "ti-ink-pen",
  "ti-info-alt",
  "ti-help-alt",
  "ti-headphone-alt",
  "ti-gallery",
  "ti-face-smile",
  "ti-face-sad",
  "ti-credit-card",
  "ti-comments-smiley",
  "ti-time",
  "ti-share",
  "ti-share-alt",
  "ti-rocket",
  "ti-new-window",
  "ti-rss",
  "ti-rss-alt",
];
