import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import pkg from "lodash";
import { Alert } from "antd";
import { setEditingUserClose } from "../../../data/redux/auth/auth";
import { createUser, editUser } from "../../../data/redux/auth/thunk";

const { mapValues, merge, pick } = pkg;

const getInitialValues = (user: any) => {
  let newUser = {
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    ip_addresses: "",
  };

  if (user) {
    // Replace null values with empty strings
    const sanitizedUser = mapValues(user, (value) =>
      value === null ? "" : value
    );
    newUser = merge({}, newUser, sanitizedUser);
  }

  return pick(newUser, [
    "username",
    "email",
    "first_name",
    "last_name",
    "ip_addresses",
  ]);
};

const AddUserModal = () => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.auth?.users?.edit?.loading
  );
  const editingData = useSelector(
    (state: any) => state?.auth?.users?.edit?.data
  );

  const formik = useFormik({
    initialValues: getInitialValues(editingData),
    validationSchema: Yup.object().shape({
      username: Yup.string().max(255).required("UserName is required"),
      email: Yup.string()
        .max(255)
        .required("Email is required")
        .email("Must be a valid email"),
      first_name: Yup.string().max(255),
      last_name: Yup.string().max(255),
      ip_addresses: Yup.string().max(255),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        const newUser = values;

        if (editingData) {
          await dispatch(editUser({ id: editingData.id, data: newUser }));
        } else {
          await dispatch(createUser(newUser));
        }
        resetForm();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
  } = formik;

  const onCloseModal = () => {
    dispatch(setEditingUserClose());
  };

  return (
    <FormikProvider value={formik}>
      {/* Add/Edit Menu */}
      <div
        className={modalToggle ? "toggle-popup sidebar-popup" : "toggle-popup"}
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>{editingData ? "Edit Menu" : "Add New Menu"}</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => onCloseModal()}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {/* Menu Info */}

                <div className="row gy-2">
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Username <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        value={values.username}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.username && errors.username && (
                      <Alert message={errors.username as string} type="error" />
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.email && errors.email && (
                      <Alert message={errors.email as string} type="error" />
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        value={values.first_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.first_name && errors.first_name && (
                      <Alert
                        message={errors.first_name as string}
                        type="error"
                      />
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        className="form-control"
                        value={values.last_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.last_name && errors.last_name && (
                      <Alert
                        message={errors.last_name as string}
                        type="error"
                      />
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Ip Addresses <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="ip_addresses"
                        className="form-control"
                        value={values.ip_addresses}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.ip_addresses && errors.ip_addresses && (
                      <Alert
                        message={errors.ip_addresses as string}
                        type="error"
                      />
                    )}
                  </div>
                </div>

                <div className="submit-button text-end">
                  <Link
                    to="#"
                    className="btn btn-light sidebar-close"
                    onClick={() => onCloseModal()}
                  >
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    {editingData ? "Edit" : "Create"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add/Edit Group */}
    </FormikProvider>
  );
};

export default AddUserModal;
