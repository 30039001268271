import axios from "../utils/axios";

class ContactsService {
  // Contact Actions
  async getContacts(payload) {
    try {
      const response = await axios.get("/api/contact/contacts-new/");
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async filterSortContacts(
    filterData,
    termData = null,
    order = "asc",
    current = 1,
    pageSize = 20
  ) {
    const checkedFilters = {};
    Object.keys(filterData).forEach((field) => {
      const checkedValues = Object.keys(filterData?.[field] || {}).filter(
        (value) => filterData?.[field]?.[value]
      );
      if (checkedValues.length) {
        checkedFilters[field] = checkedValues;
      }
    });
    const payload = {
      filters: checkedFilters,
    };
    if (termData) {
      payload["term"] = termData;
    }

    payload["order"] = order;
    try {
      const response = await axios.post(
        `/api/contact/contacts-new/filter_sort_data/?page=${current}&page_size=${pageSize}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async getContact(contact_id) {
    try {
      const response = await axios.get(
        `/api/contact/contacts-new/${contact_id}/`
      );
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async createContact(payload) {
    try {
      const response = await axios.post("/api/contact/contacts-new/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async editContact(payload) {
    const { contact_id, data } = payload;
    try {
      const response = await axios.put(
        `/api/contact/contacts-new/${contact_id}/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async removeContact(contact_id) {
    try {
      await axios.delete(`/api/contact/contacts-new/${contact_id}/`);
      return contact_id;
    } catch (err) {
      return null;
    }
  }

  async searchFieldValues(fieldName, term) {
    try {
      const response = await axios.get(
        `/api/contact/contact-fields/search/?field=${fieldName}&search=${term}`
      );
      return response.data;
    } catch (err) {
      return null;
    }
  }
}

export default new ContactsService();
