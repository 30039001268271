import { createSlice } from "@reduxjs/toolkit";
import { filterSortLead, getLead, getLeads } from "./thunk";
import { sortBy } from "lodash";
import { TablePaginationConfig } from "antd";

const initialState: {
  leads: {
    loading: boolean;
    list: any[];
    pagination: TablePaginationConfig;
    edit: {
      data: any;
      loading: boolean;
    };
    view: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
  };
} = {
  leads: {
    loading: false,
    list: [],
    edit: {
      data: null,
      loading: false,
    },
    view: {
      data: null,
      loading: false,
    },
    pagination: {
      total: 0,
      current: 1,
    },
    delete: {
      data: null,
    },
  },
};

export const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    // Lead Actions
    setEditingLead: (state, action) => {
      state.leads = {
        ...(state.leads || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingLeadClose: (state) => {
      state.leads = {
        ...(state.leads || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveLead: (state, action) => {
      state.leads = {
        ...(state.leads || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveLeadClose: (state) => {
      state.leads = {
        ...(state.leads || {}),
        delete: {
          data: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Leads Actions
      .addCase(getLeads.pending, (state, action) => {
        state.leads = {
          ...(state.leads || {}),
          loading: true,
          pagination: {
            total: 0,
            current: 1,
          },
        };
      })
      .addCase(getLeads.fulfilled, (state, action) => {
        const { results, count } = action.payload;
        state.leads = {
          ...(state.leads || {}),
          loading: false,
          list: results,
          pagination: {
            total: count,
            current: action.meta.arg?.currentPage || 1,
          },
        };
      })
      .addCase(getLeads.rejected, (state, action) => {
        state.leads = {
          ...(state.leads || {}),
          loading: false,
        };
      })
      .addCase(getLead.pending, (state, action) => {
        state.leads = {
          ...(state.leads || {}),
          view: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(getLead.fulfilled, (state, action) => {
        state.leads = {
          ...(state.leads || {}),
          view: {
            data: action.payload,
            loading: false,
          },
        };
      })
      .addCase(getLead.rejected, (state, action) => {
        state.leads = {
          ...(state.leads || {}),
          view: {
            data: null,
            loading: false,
          },
        };
      })
      .addCase(filterSortLead.pending, (state, action) => {
        state.leads = {
          ...(state.leads || {}),
          loading: true,
        };
      })
      .addCase(filterSortLead.fulfilled, (state, action) => {
        const { results, count } = action.payload;
        state.leads = {
          ...(state.leads || {}),
          loading: false,
          list: results,
          pagination: {
            total: count,
            current: action.meta.arg?.currentPage || 1,
          },
        };
      })
      .addCase(filterSortLead.rejected, (state, action) => {
        state.leads = {
          ...(state.leads || {}),
          loading: false,
        };
      });
  },
});

export const {
  setEditingLead,
  setEditingLeadClose,
  setRemoveLead,
  setRemoveLeadClose,
} = leadSlice.actions;

export default leadSlice.reducer;
