import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../core/common/dataTable/index";
import { useDispatch, useSelector } from "react-redux";
import CollapseHeader from "../../../core/common/collapse-header";
import { Flex, Tag } from "antd";
// import {
//   editContactType,
//   getContactTypes,
//   retrieveContactFields,
// } from "../../../core/data/redux/contacts/thunk";
// import {
//   setEditingContactType,
//   setRemoveContactType,
// } from "../../../core/data/redux/contacts/contacts";
import {
  editAdminField,
  getAdminFields,
  retrieveAdminFields,
} from "../../../core/data/redux/fieldsAdmin/thunk";
import {
  setEditFieldsAdmin,
  setRemoveFieldsAdmin,
} from "../../../core/data/redux/fieldsAdmin/fieldsAdmin";
import AddFieldAdminModal from "../../../core/modals/admin/fieldAdmin/add_field_admin_modal";
import RemoveFieldAdminModal from "../../../core/modals/admin/fieldAdmin/remove_field_admin_modal";

const PAGE_ROOT = "contact";

const AdminContacts = () => {
  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const fieldsAdminDataLoading = useSelector(
    (state: any) => state.fieldsAdmin.admin?.loading
  );

  const dispatch = useDispatch<any>();

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 60,
      className: "drag-visible",
    },
    {
      title: "Original Field Name",
      dataIndex: "original_name",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span className="profile-split d-flex flex-column cursor-pointer">
            {record.original_name}
          </span>
        </div>
      ),
    },
    {
      title: "Original Field Type",
      dataIndex: "original_field_type",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span className="profile-split d-flex flex-column cursor-pointer text-uppercase">
            {record.original_field_type}
          </span>
        </div>
      ),
    },
    {
      title: "Display Field Name",
      dataIndex: "display_name",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span className="profile-split d-flex flex-column cursor-pointer">
            {record.display_name}
          </span>
        </div>
      ),
    },
    {
      title: "Display Field Type",
      dataIndex: "display_field_type",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span className="profile-split d-flex flex-column cursor-pointer text-uppercase">
            {record.display_field_type}
          </span>
        </div>
      ),
    },

    {
      title: "Assigned Groups",
      dataIndex: "assigned_user_groups",
      render: (text: string, record: any, index: number) => (
        <Flex gap="4px 0">
          {(record?.assigned_user_groups || []).map((group: any) => (
            <Tag color="processing" key={group.name}>
              {group.name}
            </Tag>
          ))}
        </Flex>
      ),
    },
    {
      title: "Is Show",
      dataIndex: "is_show",
      render: (text: string, record: any, index: number) => (
        <Flex gap="4px 0" className="align-items-center">
          <div className="form-switch form-check-md">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={record.is_show}
              disabled
            />
          </div>
        </Flex>
      ),
    },
    {
      title: "Is Required",
      dataIndex: "is_required",
      render: (text: string, record: any, index: number) => (
        <Flex gap="4px 0" className="align-items-center">
          <div className="form-switch form-check-md">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={record.is_required}
              disabled
            />
          </div>
        </Flex>
      ),
    },
    {
      title: "Is Filter",
      dataIndex: "is_filter",
      render: (text: string, record: any, index: number) => (
        <Flex gap="4px 0" className="align-items-center">
          <div className="form-switch form-check-md">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={record.is_filter}
              disabled
            />
          </div>
        </Flex>
      ),
    },
    {
      title: "Width",
      dataIndex: "width",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span className="profile-split d-flex flex-column cursor-pointer">
            {record.width}px
          </span>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text: string, record: any, index: number) => (
        <div className="dropdown table-action" key={index}>
          <Link
            to="#"
            className="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="fa fa-ellipsis-v"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              margin: "0px",
              transform: "translate3d(-99.3333px, 35.3333px, 0px)",
            }}
            data-popper-placement="bottom-start"
          >
            <Link
              className="dropdown-item edit-popup"
              to="#"
              onClick={() => dispatch(setEditFieldsAdmin(record))}
            >
              <i className="ti ti-edit text-blue"></i> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => dispatch(setRemoveFieldsAdmin(record.id))}
            >
              <i className="ti ti-trash text-danger"></i> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const init = async () => {
      dispatch(
        getAdminFields({
          page_root: PAGE_ROOT,
        })
      );
    };

    init();
  }, [dispatch]);

  const onDragEnd = async (recordId: string, newOrder: number) => {
    await dispatch(
      editAdminField({
        id: recordId,
        data: {
          order: newOrder + 1,
        },
        is_new_order: true,
      })
    );
    dispatch(
      getAdminFields({
        page_root: PAGE_ROOT,
      })
    );
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">
                      Contact Admin
                      {/* {contactTypesData?.length ? (
                        <span className="count-title">
                          {contactTypesData?.length}
                        </span>
                      ) : null} */}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(
                            getAdminFields({
                              page_root: PAGE_ROOT,
                              model_name: "ContactNew",
                              model_category: "main",
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="col-md-5 col-sm-4">
                      <div className="form-wrap icon-form">
                        <span className="form-icon">
                          <i className="ti ti-search" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Conact Type"
                        />
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          {!fieldsAdminData[PAGE_ROOT]?.["ContactNew"]?.["main"]
                            ?.data?.length && (
                            <li>
                              <Link
                                to="#"
                                className="btn btn-primary add-popup"
                                onClick={() =>
                                  dispatch(
                                    retrieveAdminFields({
                                      page_root: PAGE_ROOT,
                                      model_name: "ContactNew",
                                      model_category: "main",
                                    })
                                  )
                                }
                              >
                                <i className="ti ti-square-rounded-plus" />{" "}
                                Retrieve Contact Admin
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Contact Admin List */}
                <div className="table-responsive custom-table">
                  <Table
                    dataSource={
                      fieldsAdminData[PAGE_ROOT]?.["ContactNew"]?.["main"]?.data
                    }
                    columns={columns}
                    onDragEnd={onDragEnd}
                    loading={
                      fieldsAdminDataLoading ||
                      fieldsAdminData[PAGE_ROOT]?.["ContactNew"]?.["main"]
                        ?.loading
                    }
                  />
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="datatable-length" />
                  </div>
                  <div className="col-md-6">
                    <div className="datatable-paginate" />
                  </div>
                </div>
                {/* /Contact Admin List */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">
                      Lead Admin
                      {/* {contactTypesData?.length ? (
                        <span className="count-title">
                          {contactTypesData?.length}
                        </span>
                      ) : null} */}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(
                            getAdminFields({
                              page_root: PAGE_ROOT,
                              model_name: "Lead",
                              model_category: "main",
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="col-md-5 col-sm-4">
                      <div className="form-wrap icon-form">
                        <span className="form-icon">
                          <i className="ti ti-search" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Conact Type"
                        />
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          {!fieldsAdminData[PAGE_ROOT]?.["Lead"]?.["main"]?.data
                            ?.length && (
                            <li>
                              <Link
                                to="#"
                                className="btn btn-primary add-popup"
                                onClick={() =>
                                  dispatch(
                                    retrieveAdminFields({
                                      page_root: PAGE_ROOT,
                                      model_name: "Lead",
                                      model_category: "main",
                                    })
                                  )
                                }
                              >
                                <i className="ti ti-square-rounded-plus" />{" "}
                                Retrieve Lead Admin
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Contact Admin List */}
                <div className="table-responsive custom-table">
                  <Table
                    dataSource={
                      fieldsAdminData[PAGE_ROOT]?.["Lead"]?.["main"]?.data
                    }
                    columns={columns}
                    onDragEnd={onDragEnd}
                    loading={
                      fieldsAdminDataLoading ||
                      fieldsAdminData[PAGE_ROOT]?.["Lead"]?.["main"]?.loading
                    }
                  />
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="datatable-length" />
                  </div>
                  <div className="col-md-6">
                    <div className="datatable-paginate" />
                  </div>
                </div>
                {/* /Contact Admin List */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">
                      Closed Lead Admin
                      {/* {contactTypesData?.length ? (
                        <span className="count-title">
                          {contactTypesData?.length}
                        </span>
                      ) : null} */}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(
                            getAdminFields({
                              page_root: PAGE_ROOT,
                              model_name: "Lead",
                              model_category: "closed",
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="col-md-5 col-sm-4">
                      <div className="form-wrap icon-form">
                        <span className="form-icon">
                          <i className="ti ti-search" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Conact Type"
                        />
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          {!fieldsAdminData[PAGE_ROOT]?.["Lead"]?.["closed"]
                            ?.data?.length && (
                            <li>
                              <Link
                                to="#"
                                className="btn btn-primary add-popup"
                                onClick={() =>
                                  dispatch(
                                    retrieveAdminFields({
                                      page_root: PAGE_ROOT,
                                      model_name: "Lead",
                                      model_category: "closed",
                                    })
                                  )
                                }
                              >
                                <i className="ti ti-square-rounded-plus" />{" "}
                                Retrieve Closed Lead Admin
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Contact Admin List */}
                <div className="table-responsive custom-table">
                  <Table
                    dataSource={
                      fieldsAdminData[PAGE_ROOT]?.["Lead"]?.["closed"]?.data
                    }
                    columns={columns}
                    onDragEnd={onDragEnd}
                    loading={
                      fieldsAdminDataLoading ||
                      fieldsAdminData[PAGE_ROOT]?.["Lead"]?.["closed"]?.loading
                    }
                  />
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="datatable-length" />
                  </div>
                  <div className="col-md-6">
                    <div className="datatable-paginate" />
                  </div>
                </div>
                {/* /Contact Admin List */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">
                      Company Renewal Admin
                      {/* {contactTypesData?.length ? (
                        <span className="count-title">
                          {contactTypesData?.length}
                        </span>
                      ) : null} */}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(
                            getAdminFields({
                              page_root: PAGE_ROOT,
                              model_name: "CompanyRenewal",
                              model_category: "main",
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="col-md-5 col-sm-4">
                      <div className="form-wrap icon-form">
                        <span className="form-icon">
                          <i className="ti ti-search" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Conact Type"
                        />
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          {!fieldsAdminData[PAGE_ROOT]?.["CompanyRenewal"]?.[
                            "main"
                          ]?.data?.length && (
                            <li>
                              <Link
                                to="#"
                                className="btn btn-primary add-popup"
                                onClick={() =>
                                  dispatch(
                                    retrieveAdminFields({
                                      page_root: PAGE_ROOT,
                                      model_name: "CompanyRenewal",
                                      model_category: "main",
                                    })
                                  )
                                }
                              >
                                <i className="ti ti-square-rounded-plus" />{" "}
                                Retrieve CompanyRenewal Admin
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Contact Admin List */}
                <div className="table-responsive custom-table">
                  <Table
                    dataSource={
                      fieldsAdminData[PAGE_ROOT]?.["CompanyRenewal"]?.["main"]
                        ?.data
                    }
                    columns={columns}
                    onDragEnd={onDragEnd}
                    loading={
                      fieldsAdminDataLoading ||
                      fieldsAdminData[PAGE_ROOT]?.["CompanyRenewal"]?.["main"]
                        ?.loading
                    }
                  />
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="datatable-length" />
                  </div>
                  <div className="col-md-6">
                    <div className="datatable-paginate" />
                  </div>
                </div>
                {/* /Contact Admin List */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddFieldAdminModal />
      <RemoveFieldAdminModal />
    </>
  );
};

export default AdminContacts;
