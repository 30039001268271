import { createAsyncThunk } from "@reduxjs/toolkit";
import MenuService from "../../../../services/menu";

// Menu Group Actions
export const getMenuGroups = createAsyncThunk(
  "menu/retrieve-groups",
  async () => {
    const response = await MenuService.getMenuGroups();
    if (!response) {
      throw new Error("Retrieve Groups Failed");
    }

    return response;
  }
);

export const getMenuGroup = createAsyncThunk(
  "menu/retrieve-group",
  async (payload: any) => {
    const response = await MenuService.getMenuGroup(payload);
    if (!response) {
      throw new Error("Retrieve Group Failed");
    }

    return response;
  }
);

export const createMenuGroup = createAsyncThunk(
  "menu/create-group",
  async (payload: any) => {
    const response = await MenuService.createNewMenuGroup(payload);
    if (!response) {
      throw new Error("Create Menu Group Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const editMenuGroup = createAsyncThunk(
  "menu/edit-group",
  async (payload: any) => {
    const { is_new_order, ...rest } = payload;
    const response = await MenuService.editMenuGroup(rest);
    if (!response) {
      throw new Error("Edit Menu Group Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const deleteMenuGroup = createAsyncThunk(
  "menu/delete-group",
  async (payload: any) => {
    const response = await MenuService.removeMenuGroup(payload);
    if (!response) {
      throw new Error("delete Menu Group Failed!");
    }

    return response;
  }
);

// Menu Group Actions
export const getMenus = createAsyncThunk("menu/retrieve-menus", async () => {
  const response = await MenuService.getMenus();
  if (!response) {
    throw new Error("Retrieve Groups Failed");
  }

  return response;
});

export const createMenu = createAsyncThunk(
  "menu/create-menu",
  async (payload: any) => {
    const response = await MenuService.createNewMenu(payload);
    if (!response) {
      throw new Error("Create Menu Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const getMenu = createAsyncThunk(
  "menu/retrieve-menu",
  async (payload: any) => {
    const { is_new_order, ...rest } = payload;
    const response = await MenuService.getMenu(rest);
    if (!response) {
      throw new Error("Get Menu Failed!");
    }

    return response;
  }
);

export const editMenu = createAsyncThunk(
  "menu/edit-menu",
  async (payload: any) => {
    const response = await MenuService.editMenu(payload);
    if (!response) {
      throw new Error("Edit Menu Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const deleteMenu = createAsyncThunk(
  "menu/delete-menu",
  async (payload: any) => {
    const response = await MenuService.removeMenu(payload);
    if (!response) {
      throw new Error("Remove Menu Failed!");
    }

    return response;
  }
);

export const getJWTToken = createAsyncThunk(
  "menu/get-tableau-token",
  async () => {
    const response = await MenuService.getJWT();
    if (!response) {
      throw new Error("Get JWT Failed!");
    }

    return response;
  }
);
