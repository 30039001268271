import { createSlice } from "@reduxjs/toolkit";
import {
  createContact,
  editContact,
  getContact,
  getContacts,
  deleteContact,
  filterSortContacts,
} from "./thunk";
import { sortBy } from "lodash";
import { TablePaginationConfig } from "antd";

const initialState: {
  contacts: {
    loading: boolean;
    list: any[];
    pagination: TablePaginationConfig;
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
  };
  contactTypes: {
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
    loading: boolean;
    list: any[];
  };
} = {
  contacts: {
    loading: false,
    list: [],
    edit: {
      data: null,
      loading: false,
    },
    pagination: {
      total: 0,
      current: 1,
    },
    delete: {
      data: null,
    },
  },
  contactTypes: {
    loading: true,
    edit: {
      data: null,
      loading: false,
    },
    delete: {
      data: null,
    },
    list: [],
  },
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    // Contact Actions
    setEditingContact: (state, action) => {
      state.contacts = {
        ...(state.contacts || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingContactClose: (state) => {
      state.contacts = {
        ...(state.contacts || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveContact: (state, action) => {
      state.contacts = {
        ...(state.contacts || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveContactClose: (state) => {
      state.contacts = {
        ...(state.contacts || {}),
        delete: {
          data: null,
        },
      };
    },
    // Contact Admin Actions
    setEditingContactType: (state, action) => {
      state.contactTypes = {
        ...(state.contactTypes || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingContactTypeClose: (state) => {
      state.contactTypes = {
        ...(state.contactTypes || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveContactType: (state, action) => {
      state.contactTypes = {
        ...(state.contactTypes || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveContactTypeClose: (state) => {
      state.contactTypes = {
        ...(state.contactTypes || {}),
        delete: {
          data: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Contact Actions
      .addCase(createContact.pending, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(createContact.fulfilled, (state, action) => {
        const contactData: any = action.payload;
        state.contacts = {
          ...(state.contacts || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: [...state.contacts.list, contactData],
        };
      })
      .addCase(createContact.rejected, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(editContact.pending, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          edit: {
            ...(state.contacts.edit || {}),
            loading: true,
          },
        };
      })
      .addCase(editContact.fulfilled, (state, action) => {
        const contactData: any = action.payload;
        state.contacts = {
          ...(state.contacts || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: [
            ...state.contacts.list?.filter(
              (item) => item.id !== contactData.id
            ),
            contactData,
          ],
        };
      })
      .addCase(editContact.rejected, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(getContact.pending, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          loading: true,
        };
      })
      .addCase(getContact.fulfilled, (state, action) => {
        const contactData = action.payload;
        const existingData = (state.contacts.list || []).filter(
          (item: any) => item.id !== contactData?.id
        );
        state.contacts = {
          ...(state.contacts || {}),
          loading: false,
          list: [...existingData, contactData],
        };
      })
      .addCase(getContact.rejected, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          loading: false,
        };
      })
      .addCase(getContacts.pending, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          loading: true,
          pagination: {
            total: 0,
            current: 1,
          },
        };
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        const { results, count } = action.payload;
        state.contacts = {
          ...(state.contacts || {}),
          loading: false,
          list: results,
          pagination: {
            total: count,
            current: action.meta.arg?.currentPage || 1,
          },
        };
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          loading: false,
        };
      })
      .addCase(filterSortContacts.pending, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          loading: true,
        };
      })
      .addCase(filterSortContacts.fulfilled, (state, action) => {
        const { results, count } = action.payload;
        state.contacts = {
          ...(state.contacts || {}),
          loading: false,
          list: results,
          pagination: {
            total: count,
            current: action.meta.arg?.currentPage || 1,
          },
        };
      })
      .addCase(filterSortContacts.rejected, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          loading: false,
        };
      })
      .addCase(deleteContact.pending, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          loading: true,
        };
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        const contactId = action.payload;
        state.contacts = {
          ...(state.contacts || {}),
          loading: false,
          list: (state.contacts.list || []).filter(
            (item: any) => item.id !== contactId
          ),
          delete: {
            data: null,
          },
        };
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.contacts = {
          ...(state.contacts || {}),
          loading: false,
        };
      });
  },
});

export const {
  setEditingContactType,
  setEditingContactTypeClose,
  setRemoveContactType,
  setRemoveContactTypeClose,
  setEditingContact,
  setEditingContactClose,
  setRemoveContact,
  setRemoveContactClose,
} = contactSlice.actions;

export default contactSlice.reducer;
