import { createSlice } from "@reduxjs/toolkit";
import {
  deleteAdminField,
  editAdminField,
  getAdminField,
  getAdminFields,
  retrieveAdminFields,
} from "./thunk";
import { sortBy, groupBy, mapValues } from "lodash";
import { TablePaginationConfig } from "antd";

const initialState: {
  admin: {
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
    loading: boolean;
    list: {
      [pageRoot: string]: {
        [model: string]: {
          [category: string]: {
            data: any[];
            loading: boolean;
          };
        };
      };
    };
  };
} = {
  admin: {
    loading: false,
    edit: {
      data: null,
      loading: false,
    },
    delete: {
      data: null,
    },
    list: {},
  },
};

export const fieldsAdminSlice = createSlice({
  name: "fieldsAdmin",
  initialState,
  reducers: {
    // Field Admin Actions
    setEditFieldsAdmin: (state, action) => {
      state.admin = {
        ...(state.admin || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditFieldsAdminClose: (state) => {
      state.admin = {
        ...(state.admin || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveFieldsAdmin: (state, action) => {
      state.admin = {
        ...(state.admin || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveFieldsAdminClose: (state) => {
      state.admin = {
        ...(state.admin || {}),
        delete: {
          data: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Contact Type Actions
      // .addCase(createContactType.pending, (state, action) => {
      //   state.admin = {
      //     ...(state.admin || {}),
      //     edit: {
      //       data: null,
      //       loading: true,
      //     },
      //   };
      // })
      // .addCase(createContactType.fulfilled, (state, action) => {
      //   const contactTypeData: any = action.payload;
      //   state.admin = {
      //     ...(state.admin || {}),
      //     edit: {
      //       data: null,
      //       loading: false,
      //     },
      //     list: sortBy([...state.contactTypes.list, contactTypeData], "order"),
      //   };
      // })
      // .addCase(createContactType.rejected, (state, action) => {
      //   state.contactTypes = {
      //     ...(state.contactTypes || {}),
      //     edit: {
      //       data: null,
      //       loading: true,
      //     },
      //   };
      // })
      .addCase(editAdminField.pending, (state, action) => {
        const isNewOrder = !!action.meta.arg?.["is_new_order"];

        state.admin = {
          ...(state.admin || {}),
          edit: {
            ...(state.admin.edit || {}),
            loading: !isNewOrder,
          },
        };
      })
      .addCase(editAdminField.fulfilled, (state, action) => {
        const adminFieldData: any = action.payload;
        const { page_root, model_name, model_category } = adminFieldData;
        const categoryData =
          state.admin.list?.[page_root]?.[model_name]?.[model_category]?.data ||
          [];

        const result = sortBy(
          [
            ...categoryData?.filter((item) => item.id !== adminFieldData.id),
            adminFieldData,
          ],
          "order"
        );

        state.admin = {
          ...(state.admin || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: {
            ...(state.admin.list || {}),
            [page_root]: {
              ...(state.admin.list?.[page_root] || {}),
              [model_name]: {
                ...(state.admin.list?.[page_root]?.[model_name] || {}),
                [model_category]: {
                  data: result,
                  loading: false,
                },
              },
            },
          },
        };
      })
      .addCase(editAdminField.rejected, (state, action) => {
        state.admin = {
          ...(state.admin || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(getAdminField.pending, (state, action) => {
        state.admin = {
          ...(state.admin || {}),
          loading: true,
        };
      })
      .addCase(getAdminField.fulfilled, (state, action) => {
        const groupData = action.payload;
        // const existingData = (state.admin.list || []).filter(
        //   (item: any) => item.id !== groupData?.id
        // );
        state.admin = {
          ...(state.admin || {}),
          loading: false,
          // list: sortBy([...existingData, groupData], "order"),
        };
      })
      .addCase(getAdminField.rejected, (state, action) => {
        state.admin = {
          ...(state.admin || {}),
          loading: false,
        };
      })
      .addCase(getAdminFields.pending, (state, action) => {
        const pageRoot: string = action.meta.arg?.["page_root"] || "";
        const modelName = action.meta.arg?.["model_name"];
        const modelCategory = action.meta.arg?.["model_category"];

        if (pageRoot && modelName && modelCategory) {
          state.admin = {
            ...(state.admin || {}),
            list: {
              ...(state.admin.list || {}),
              [pageRoot]: {
                ...(state.admin.list?.[pageRoot] || {}),
                [modelName]: {
                  ...(state.admin.list?.[pageRoot]?.[modelName] || {}),
                  [modelCategory]: {
                    ...(state.admin.list?.[pageRoot]?.[modelName]?.[
                      modelCategory
                    ] || {}),
                    loading: true,
                  },
                },
              },
            },
          };
        } else {
          state.admin = {
            ...(state.admin || {}),
            loading: true,
          };
        }
      })
      .addCase(getAdminFields.fulfilled, (state, action) => {
        const adminData = action.payload;
        const pageRoot = action.meta.arg?.["page_root"] || "";
        const modelName = action.meta.arg?.["model_name"];
        const modelCategory = action.meta.arg?.["model_category"];

        if (pageRoot && modelName && modelCategory) {
          const result = sortBy(adminData, "order");
          state.admin = {
            ...(state.admin || {}),
            list: {
              ...(state.admin.list || {}),
              [pageRoot]: {
                ...(state.admin.list?.[pageRoot] || {}),
                [modelName]: {
                  ...(state.admin.list?.[pageRoot]?.[modelName] || {}),
                  [modelCategory]: {
                    data: result,
                    loading: false,
                  },
                },
              },
            },
          };
        } else {
          const groupedData = mapValues(
            groupBy(adminData, "model_name"),
            (group) =>
              mapValues(groupBy(group, "model_category"), (items) => ({
                data: items,
                loading: false,
              }))
          );
          state.admin = {
            ...(state.admin || {}),
            loading: false,
            list: {
              ...(state.admin.list || {}),
              [pageRoot]: groupedData,
            },
          };
        }
      })
      .addCase(getAdminFields.rejected, (state, action) => {
        const pageRoot = action.meta.arg?.["page_root"];
        const modelName = action.meta.arg?.["model_name"];
        const modelCategory = action.meta.arg?.["model_category"];

        if (pageRoot && modelName && modelCategory) {
          state.admin = {
            ...(state.admin || {}),
            list: {
              ...(state.admin.list || {}),
              [pageRoot]: {
                ...(state.admin.list?.[pageRoot] || {}),
                [modelName]: {
                  ...(state.admin.list?.[pageRoot]?.[modelName] || {}),
                  [modelCategory]: {
                    ...(state.admin.list?.[pageRoot]?.[modelName]?.[
                      modelCategory
                    ] || {}),
                    loading: true,
                  },
                },
              },
            },
          };
        } else {
          state.admin = {
            ...(state.admin || {}),
            loading: true,
          };
        }
      })
      .addCase(retrieveAdminFields.pending, (state, action) => {
        const pageRoot: string = action.meta.arg?.["page_root"] || "";
        const modelName = action.meta.arg?.["model_name"];
        const modelCategory = action.meta.arg?.["model_category"];

        if (pageRoot && modelName && modelCategory) {
          state.admin = {
            ...(state.admin || {}),
            list: {
              ...(state.admin.list || {}),
              [pageRoot]: {
                ...(state.admin.list?.[pageRoot] || {}),
                [modelName]: {
                  ...(state.admin.list?.[pageRoot]?.[modelName] || {}),
                  [modelCategory]: {
                    ...(state.admin.list?.[pageRoot]?.[modelName]?.[
                      modelCategory
                    ] || {}),
                    loading: true,
                  },
                },
              },
            },
          };
        } else {
          state.admin = {
            ...(state.admin || {}),
            loading: true,
          };
        }
      })
      .addCase(retrieveAdminFields.fulfilled, (state, action) => {
        const adminData = action.payload;
        const pageRoot = action.meta.arg?.["page_root"] || "";
        const modelName = action.meta.arg?.["model_name"];
        const modelCategory = action.meta.arg?.["model_category"];

        if (pageRoot && modelName && modelCategory) {
          const result = sortBy(adminData, "order");
          console.log(adminData);
          state.admin = {
            ...(state.admin || {}),
            list: {
              ...(state.admin.list || {}),
              [pageRoot]: {
                ...(state.admin.list?.[pageRoot] || {}),
                [modelName]: {
                  ...(state.admin.list?.[pageRoot]?.[modelName] || {}),
                  [modelCategory]: {
                    data: result,
                    loading: false,
                  },
                },
              },
            },
          };
        } else {
          const groupedData = mapValues(
            groupBy(adminData, "model_name"),
            (group) =>
              mapValues(groupBy(group, "model_category"), (items) => ({
                data: items,
                loading: false,
              }))
          );
          state.admin = {
            ...(state.admin || {}),
            loading: false,
            list: {
              ...(state.admin.list || {}),
              [pageRoot]: groupedData,
            },
          };
        }
      })
      .addCase(retrieveAdminFields.rejected, (state, action) => {
        state.admin = {
          ...(state.admin || {}),
          loading: false,
        };
      })
      .addCase(deleteAdminField.pending, (state, action) => {
        state.admin = {
          ...(state.admin || {}),
          loading: true,
        };
      })
      .addCase(deleteAdminField.fulfilled, (state, action) => {
        const groupId = action.payload;
        state.admin = {
          ...(state.admin || {}),
          loading: false,
          // list: (state.admin.list || []).filter(
          //   (item: any) => item.id !== groupId
          // ),
          delete: {
            data: null,
          },
        };
      })
      .addCase(deleteAdminField.rejected, (state, action) => {
        state.admin = {
          ...(state.admin || {}),
          loading: false,
        };
      });
  },
});

export const {
  setEditFieldsAdmin,
  setEditFieldsAdminClose,
  setRemoveFieldsAdmin,
  setRemoveFieldsAdminClose,
} = fieldsAdminSlice.actions;

export default fieldsAdminSlice.reducer;
