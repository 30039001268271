import { configureStore } from "@reduxjs/toolkit";

import { useDispatch } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import rootReducer from "./rootReducer";
import secureStorageWrapper from "../../storage/wrapper";

const ENABLE_REDUX_DEV_TOOLS = true;

const persistConfig = {
  key: "root",
  storage: secureStorageWrapper,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: ENABLE_REDUX_DEV_TOOLS,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

export const persistor = persistStore(store);
