import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ImageWithBasePath from "../imageWithBasePath";
import { all_routes } from "../../../feature-module/router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import {
  setExpandMenu,
  setMiniSidebar,
  setMobileSidebar,
} from "../../data/redux/common";
import { List, Typography } from "antd";
import { logout } from "../../data/redux/auth/thunk";
import secureLocalStorage from "react-secure-storage";
const localStorage = secureLocalStorage;

const Header = () => {
  const route = all_routes;
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const mobileSidebar = useSelector((state: any) => state.common.mobileSidebar);
  const miniSidebar = useSelector((state: any) => state.common.miniSidebar);

  const toggleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };
  const toggleMiniSidebar = () => {
    dispatch(setMiniSidebar(!miniSidebar));
  };
  const toggleExpandMenu = () => {
    dispatch(setExpandMenu(true));
  };
  const toggleExpandMenu2 = () => {
    dispatch(setExpandMenu(false));
  };

  const isLockScreen = location.pathname === "/lock-screen";

  if (isLockScreen) {
    return null;
  }

  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div
          className="header-left active"
          onMouseEnter={toggleExpandMenu}
          onMouseLeave={toggleExpandMenu2}
        >
          <Link to={route.welcomePage} className="logo logo-normal">
            {/* {layoutBs === "dark" ? (
              <>
                <ImageWithBasePath
                  src="assets/img/white-logo.png"
                  className="white-logo"
                  alt="Logo"
                />
              </>
            ) : (
              <ImageWithBasePath src="assets/img/logo.png" alt="Logo" />
            )} */}
            <ImageWithBasePath src="assets/img/logo.png" alt="Logo" />
            <ImageWithBasePath
              src="assets/img/white-logo.png"
              className="white-logo"
              alt="Logo"
            />
          </Link>
          <Link to={route.welcomePage} className="logo-small">
            <ImageWithBasePath src="assets/img/logo-small.png" alt="Logo" />
          </Link>
          <Link id="toggle_btn" to="#" onClick={toggleMiniSidebar}>
            <i className="ti ti-arrow-bar-to-left" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#sidebar"
          onClick={toggleMobileSidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        <div className="header-user">
          <ul className="nav user-menu justify-content-end">
            {/* Profile Dropdown */}
            <li className="nav-item dropdown has-arrow main-drop">
              <Link
                to="#"
                className="nav-link userset"
                data-bs-toggle="dropdown"
              >
                <span className="user-info">
                  <span className="user-letter">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-20.jpg"
                      alt="Profile"
                    />
                  </span>
                  <span className="badge badge-success rounded-pill" />
                </span>
              </Link>
              <div className={` dropdown-menu  menu-drop-user `}>
                <div className="profilename">
                  <List>
                    <List.Item className="dropdown-item">
                      <Link to={route.groups}>
                        <i className="ti ti-layout-2" /> Menu Groups
                      </Link>
                    </List.Item>
                    <List.Item className="dropdown-item">
                      <Link to={route.users}>
                        <i className="ti ti-user-pin" /> Users
                      </Link>
                    </List.Item>
                    <List.Item className="dropdown-item">
                      <Link to={route.profile}>
                        <i className="ti ti-user-pin" /> My Profile
                      </Link>
                    </List.Item>
                    <List.Item
                      className="dropdown-item"
                      onClick={async (e) => {
                        e.preventDefault();
                        await dispatch(logout());
                      }}
                    >
                      <Typography.Text>
                        <i className="ti ti-lock" /> Logout
                      </Typography.Text>
                    </List.Item>
                  </List>
                </div>
              </div>
            </li>
            {/* /Profile Dropdown */}
          </ul>
        </div>
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className={` dropdown-menu `}>
            <Link to={route.groups} className="dropdown-item">
              <i className="ti ti-layout-2" /> Menu Groups
            </Link>
            <Link to={route.users} className="dropdown-item">
              <i className="ti ti-user-pin" /> Users
            </Link>
            <Link className="dropdown-item" to={route.profile}>
              <i className="ti ti-user-pin" /> My Profile
            </Link>
            <Link className="dropdown-item" to={route.login}>
              <i className="ti ti-lock" /> Logout
            </Link>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
    </>
  );
};

export default Header;
