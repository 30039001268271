import secureStorage from "react-secure-storage";

const secureStorageWrapper = {
  getItem: (key: string) => {
    return new Promise((resolve, reject) => {
      try {
        const item = secureStorage.getItem(key);
        resolve(item);
      } catch (error) {
        reject(error);
      }
    });
  },
  setItem: (key: string, value: any) => {
    return new Promise<void>((resolve, reject) => {
      try {
        secureStorage.setItem(key, value);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  removeItem: (key: string) => {
    return new Promise<void>((resolve, reject) => {
      try {
        secureStorage.removeItem(key);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default secureStorageWrapper;
