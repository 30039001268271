import React, { useEffect, useRef, useState } from "react";
import MenuService from "../../../services/menu";
// @ts-ignore
import { TableauEventType } from "https://app.bizrampbi.com/javascripts/api/tableau.embedding.3.latest.min.js";
import { useDispatch, useSelector } from "react-redux";
import { setResetPage } from "../../../core/data/redux/common";

interface TableauWorkbooksProps {
  id: string;
  item: any;
}

const TableauWorkbooks = ({ id, item }: TableauWorkbooksProps) => {
  const vizRef = useRef(null);
  const resetPage = useSelector((state: any) => state.common.reset) || false;

  const dispatch = useDispatch<any>();

  const [jwtToken, setToken] = useState();
  const [viz, setViz] = useState();
  const [isViewAvailable, setViewAvailable] = useState(false);

  const getToken = async () => {
    const response = await MenuService.getJWT();

    if (!response) {
      throw new Error("Get JWT Failed!");
    }

    setToken(response);
  };

  const loadViz = () => {
    const applyFilters = (item?.tableau_filter_values || []).filter(
      (filter: any) =>
        filter?.name && filter?.value && filter.type === "categorical"
    );
    setViz(
      // @ts-ignore
      <tableau-viz
        ref={vizRef}
        id="tableauViz"
        src={item?.url}
        hide-tabs={false}
        token={jwtToken}
        toolbar="hidden"
        disableUrlActionsPopups={true}
      >
        {applyFilters.map((filter: any) => (
          // @ts-ignore
          <viz-filter
            key={filter.name}
            field={filter.name}
            value={filter.value}
          />
        ))}
        {/* @ts-ignore */}
      </tableau-viz>
    );
  };

  const reset = () => {
    setToken(undefined);
    setViz(undefined);
    getToken();
  };

  useEffect(() => {
    if (resetPage) {
      reset();
      dispatch(setResetPage(false));
    }
  }, [dispatch, resetPage]);

  useEffect(() => {
    reset();
  }, [id]);

  useEffect(() => {
    if (jwtToken) {
      loadViz();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtToken, item]);

  useEffect(() => {
    if (viz) {
      const vizTimeout = setTimeout(() => {
        const tableauViz = document.getElementById("tableauViz");
        if (tableauViz) {
          tableauViz.addEventListener(
            TableauEventType.FirstInteractive,
            async (event) => {
              let activeSheet = event.target?.workbook?.activeSheet;
              {
                const applyFilters: any[] = [];
                (item?.tableau_filter_values || [])
                  .filter((filter: any) => filter?.name && filter?.value)
                  .forEach((filter: any) => {
                    if (filter.type === "range") {
                      applyFilters.push(
                        activeSheet.applyRangeFilterAsync(
                          filter?.name,
                          filter?.value
                        )
                      );
                    }
                  });
                await Promise.all(applyFilters);
                setViewAvailable(true);
              }
            }
          );
        }
      }, 2000);

      return () => clearTimeout(vizTimeout);
    }
  }, [viz]);

  return (
    <div style={{ position: "relative" }}>
      {!isViewAvailable ? (
        <div className="d-flex justify-content-center mb-4">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : null}
      <div style={{ visibility: isViewAvailable ? "visible" : "hidden" }}>
        {item && viz}
      </div>
    </div>
  );
};

export default TableauWorkbooks;
