import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import { useDispatch, useSelector } from "react-redux";
import {
  createLeadComment,
  deleteLeadComment,
  editLeadComment,
  getLead,
} from "../../../core/data/redux/leads/thunk";
import Editor from "react-simple-wysiwyg";
import "./index.scss";

const route = all_routes;
const LeadsDetails = () => {
  const dispatch = useDispatch<any>();
  let { id } = useParams();

  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const leadInternalDataFields =
    fieldsAdminData?.["lead"]?.["Lead"]?.["main"]?.["data"];

  const { data: visitingLeadData, loading: isLeadDataLoading } =
    useSelector((state: any) => state.lead.leads?.view) || [];

  const [isEditingComment, setIsEditingComment] = useState<{
    [commentId: string]: boolean;
  }>({});

  const [editingCommentsContent, setEditingCommentsContent] = useState<{
    [commentId: string]: string;
  }>({});

  const onChange = (commentId: string, e: any) => {
    setEditingCommentsContent((prev) => ({
      ...prev,
      [commentId]: e.target.value,
    }));
  };

  const handleOnDeleteComment = async (commentId: string) => {
    try {
      await dispatch(deleteLeadComment(commentId));
      await dispatch(getLead(id));
    } catch (error) {}
  };

  const handleOnSaveComment = async (commentId: string) => {
    try {
      if (commentId === "add") {
        await dispatch(
          createLeadComment({
            lead: id,
            comment: editingCommentsContent?.[commentId] ?? "",
          })
        );
      } else {
        await dispatch(
          editLeadComment({
            commentId: commentId,
            lead: id,
            comment: editingCommentsContent?.[commentId] ?? "",
          })
        );
      }
      await dispatch(getLead(id));
      setIsEditingComment((prev) => ({
        ...prev,
        [commentId]: false,
      }));
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(getLead(id));
  }, [id]);

  useEffect(() => {
    const leadCommentsData = (visitingLeadData?.lead_comments || []).reduce(
      (prev: any, curr: any) => {
        Object.assign(prev, { [curr.comment_id]: curr.comment });
        return prev;
      },
      {}
    );
    setEditingCommentsContent(leadCommentsData);
  }, [JSON.stringify(visitingLeadData)]);

  if (isLeadDataLoading) {
    return (
      <div className="page-wrapper">
        <div className="content">
          <div className="preloader">
            <div className="preloader">
              <div className="loader"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">Leads Overview </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* Leads User */}
              <div className="contact-head">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <ul className="contact-breadcrumb">
                      <li>
                        <Link to={route.leads}>
                          <i className="ti ti-arrow-narrow-left" />
                          Leads
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="col-sm-6 text-sm-end">
                    <div className="contact-pagination">
                      <p>1 of 40</p>
                      <ul>
                        <li>
                          <Link to="/leads-details">
                            <i className="ti ti-chevron-left" />
                          </Link>
                        </li>
                        <li>
                          <Link to="/leads-details">
                            <i className="ti ti-chevron-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <div className="contact-wrap">
                <div className="contact-profile">
                  <div className="avatar company-avatar">
                    <span className="text-icon">HT</span>
                  </div>
                  <div className="name-user">
                    <h5>
                      Tremblay and Rath{" "}
                      <span className="star-icon">
                        <i className="fa-solid fa-star" />
                      </span>
                    </h5>
                    <p className="mb-1">
                      <i className="ti ti-building" /> SilverHawk
                    </p>
                    <p className="mb-0">
                      <i className="ti ti-map-pin-pin" /> 22, Ave Street,
                      Newyork, USA
                    </p>
                  </div>
                </div>
                <div className="contacts-action">
                  <span className="badge badge-light">
                    <i className="ti ti-lock" />
                    Private
                  </span>
                  <div className="dropdown action-drops">
                    <Link
                      to="#"
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="bg-success">
                        Closed
                        <i className="ti ti-chevron-down ms-2" />
                      </span>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <Link className="dropdown-item" to="#">
                        <span>Closed</span>
                      </Link>
                      <Link className="dropdown-item" to="#">
                        <span>Opened</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* /Leads User */}
            </div>
            {/* Leads Sidebar */}
            <div className="col-xl-3">
              <div className="contact-sidebar">
                <h6>Lead Information</h6>
                <ul className="other-info">
                  {(leadInternalDataFields || []).map((field: any) => (
                    <li key={field.original_name} className="detail-item">
                      <span className="other-title h6">
                        {field.display_name}
                      </span>
                      <span>{visitingLeadData?.[field.original_name]}</span>
                    </li>
                  ))}
                </ul>
                {/* <div className="con-sidebar-title">
                  <h6>Contacts</h6>
                  <Link
                    to="#"
                    className="com-add"
                    data-bs-toggle="modal"
                    data-bs-target="#add_contact"
                  >
                    <i className="ti ti-circle-plus me-1" />
                    Add New
                  </Link>
                </div>
                <ul className="deals-info">
                  <li>
                    <span>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-01.jpg"
                        alt="Image"
                      />
                    </span>
                    <div>
                      <p>Jessica</p>
                    </div>
                  </li>
                </ul>
                <ul className="other-info">
                  <li>
                    <span className="other-title">Last Modified</span>
                    <span>10 Jan 2024, 10:00 am</span>
                  </li>
                  <li>
                    <span className="other-title">Modified By</span>
                    <span>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-19.jpg"
                        className="avatar-xs"
                        alt="img"
                      />{" "}
                      Darlee Robertson
                    </span>
                  </li>
                </ul> */}
              </div>
            </div>
            {/* /Leads Sidebar */}
            {/* Leads Details */}
            <div className="col-xl-9">
              <div className="contact-tab-wrap">
                <ul className="contact-nav nav">
                  <li>
                    <Link
                      to="#"
                      data-bs-toggle="tab"
                      data-bs-target="#notes"
                      className="active"
                    >
                      <i className="ti ti-notes" />
                      Notes
                    </Link>
                  </li>
                </ul>
              </div>
              {/* Tab Content */}
              <div className="contact-tab-view">
                <div className="tab-content pt-0">
                  {/* Notes */}
                  <div className="tab-pane active show" id="notes">
                    <div className="view-header">
                      <h4>Notes</h4>
                      <div className="text-end">
                        <Link
                          to="#"
                          className="add-comment"
                          onClick={() => {
                            setIsEditingComment((prev) => ({
                              ...prev,
                              ["add"]: true,
                            }));
                          }}
                        >
                          <i className="ti ti-square-plus me-1" />
                          Add Comment
                        </Link>
                      </div>
                    </div>
                    <div className="notes-activity">
                      <div
                        className="calls-box"
                        style={{
                          display: isEditingComment?.["add"] ? "block" : "none",
                        }}
                      >
                        <div className="notes-editor">
                          <div className="note-edit-wrap d-block">
                            <div className="summernote">
                              <Editor
                                value={editingCommentsContent?.["add"]}
                                onChange={(e) => onChange("add", e)}
                              />
                            </div>
                            <div className="text-end note-btns">
                              <Link
                                to="#"
                                className="btn btn-light add-cancel"
                                onClick={() => {
                                  setIsEditingComment((prev) => ({
                                    ...prev,
                                    ["add"]: false,
                                  }));
                                  setEditingCommentsContent((prev) => ({
                                    ...prev,
                                    ["add"]: "",
                                  }));
                                }}
                              >
                                Cancel
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => handleOnSaveComment("add")}
                              >
                                Save
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {visitingLeadData?.lead_comments?.length ? (
                        (visitingLeadData?.lead_comments || [])?.map(
                          (leadComment: any) => (
                            <div
                              className="calls-box"
                              key={leadComment.comment_id}
                            >
                              <div className="caller-info">
                                <div className="calls-user">
                                  <ImageWithBasePath
                                    src="assets/img/profiles/avatar-19.jpg"
                                    alt="img"
                                  />
                                  <div>
                                    <h6>
                                      {`${leadComment.user.first_name} ${leadComment.user.last_name}`.trim() ||
                                        leadComment.user.username}
                                    </h6>
                                    <p>{leadComment?.created_at}</p>
                                  </div>
                                </div>
                                <div className="calls-action">
                                  <div className="dropdown action-drop">
                                    <Link
                                      to="#"
                                      className="dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ti ti-dots-vertical" />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        onClick={() => {
                                          setIsEditingComment((prev) => ({
                                            ...prev,
                                            [leadComment.comment_id]: true,
                                          }));
                                        }}
                                      >
                                        <i className="ti ti-edit text-blue" />
                                        Edit
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        onClick={() =>
                                          handleOnDeleteComment(
                                            leadComment.comment_id
                                          )
                                        }
                                      >
                                        <i className="ti ti-trash text-danger" />
                                        Delete
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p>{leadComment?.comment}</p>
                              <div className="notes-editor">
                                <div
                                  className="note-edit-wrap"
                                  style={{
                                    display: isEditingComment?.[
                                      leadComment?.comment_id
                                    ]
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  <div className="summernote">
                                    <Editor
                                      value={
                                        editingCommentsContent?.[
                                          leadComment.comment_id
                                        ]
                                      }
                                      onChange={(e) =>
                                        onChange(leadComment.comment_id, e)
                                      }
                                    />
                                  </div>
                                  <div className="text-end note-btns">
                                    <Link
                                      to="#"
                                      className="btn btn-light add-cancel"
                                      onClick={() => {
                                        setIsEditingComment((prev) => ({
                                          ...prev,
                                          [leadComment.comment_id]: false,
                                        }));
                                        setEditingCommentsContent((prev) => ({
                                          ...prev,
                                          [leadComment.comment_id]:
                                            leadComment?.comment || "",
                                        }));
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-primary"
                                      onClick={() =>
                                        handleOnSaveComment(
                                          leadComment.comment_id
                                        )
                                      }
                                    >
                                      Save
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <div className="calls-box">No Notes</div>
                      )}
                    </div>
                  </div>
                  {/* /Notes */}
                </div>
              </div>
              {/* /Tab Content */}
            </div>
            {/* /Leads Details */}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadsDetails;
