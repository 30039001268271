import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { createMenuGroup, editMenuGroup } from "../../../data/redux/menu/thunk";
import { Alert } from "antd";
import { setEditingGroupClose } from "../../../data/redux/menu/menu";
import { getErrorMessage } from "../../../../utils/helper";

const AddGroupModal = () => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.menu?.groups?.edit?.loading
  );
  const editingData = useSelector(
    (state: any) => state?.menu?.groups?.edit?.data
  );
  const availableUserGroupsData =
    useSelector((state: any) => state?.auth?.groups?.data) || [];
  const userGroupOptions = useMemo(() => {
    if (availableUserGroupsData?.length) {
      const options = availableUserGroupsData.map((group: any) => ({
        label: group.name,
        value: group.id,
      }));
      return options;
    }
    return [];
  }, [JSON.stringify(availableUserGroupsData)]);

  return (
    <>
      {/* Add/Edit Group */}
      <div
        className={modalToggle ? "toggle-popup sidebar-popup" : "toggle-popup"}
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>{editingData ? "Edit Group" : "Add New Group"}</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => dispatch(setEditingGroupClose())}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <Formik
                initialValues={{
                  name: editingData?.name || "",
                  assigned_user_groups:
                    editingData?.assigned_user_groups?.map(
                      (user: any) => user.id
                    ) || [],
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().max(255).required("Name is required"),
                  assigned_user_groups: Yup.array(),
                })}
                enableReinitialize={true}
                onSubmit={async (values, { resetForm, setErrors }) => {
                  try {
                    let newMenu: any = values;
                    newMenu.assigned_user_groups_ids =
                      values?.assigned_user_groups || [];
                    newMenu.assigned_user_groups =
                      availableUserGroupsData.filter((user: any) =>
                        (
                          (values?.assigned_user_groups || []) as any[]
                        ).includes(user.id)
                      );
                    let res: any;
                    if (editingData) {
                      res = await dispatch(
                        editMenuGroup({ id: editingData.id, data: newMenu })
                      );
                    } else {
                      res = await dispatch(createMenuGroup(newMenu));
                    }

                    if (res && res?.error) {
                      let errorData;
                      try {
                        errorData = JSON.parse(res?.error?.message);
                        setErrors(errorData);
                      } catch (_) {
                        errorData = "";
                        setErrors({ submit: getErrorMessage(errorData) });
                      }
                    } else {
                      resetForm();
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    {/* Group Info */}

                    <div className="row gy-2">
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Group Title <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                        {touched.name && errors.name && (
                          <Alert message={errors.name as string} type="error" />
                        )}
                      </div>
                      <div className="col-md-12">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Assigned Groups{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            defaultValue={[]}
                            isMulti
                            name="assigned_user_groups"
                            options={userGroupOptions}
                            onBlur={handleBlur}
                            value={
                              userGroupOptions
                                ? userGroupOptions.filter((option: any) =>
                                    (
                                      (values.assigned_user_groups ||
                                        []) as any[]
                                    ).includes(option.value)
                                  )
                                : []
                            }
                            onChange={(options: any) => {
                              setFieldValue(
                                "assigned_user_groups",
                                (options || []).map(
                                  (option: any) => option.value
                                )
                              );
                            }}
                          />
                        </div>
                        {touched.assigned_user_groups &&
                          errors.assigned_user_groups && (
                            <Alert
                              message={errors.assigned_user_groups as string}
                              type="error"
                            />
                          )}
                      </div>
                    </div>
                    <div className="row gy-2">
                      <div className="col-md-12">
                        <div className="form-wrap">
                          {errors.submit && (
                            <Alert
                              message={errors.submit as string}
                              type="error"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="submit-button text-end">
                      <Link
                        to="#"
                        className="btn btn-light sidebar-close"
                        onClick={() => dispatch(setEditingGroupClose())}
                      >
                        Cancel
                      </Link>
                      <button type="submit" className="btn btn-primary">
                        {editingData ? "Edit" : "Create"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* /Add/Edit Group */}
    </>
  );
};

export default AddGroupModal;
