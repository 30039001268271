import { createAsyncThunk } from "@reduxjs/toolkit";
import fieldsAdminService from "../../../../services/fieldsAdmin";

// Field Admin Actions
export const getAdminFields = createAsyncThunk(
  "admin/get-admin-fields",
  async (payload: any) => {
    const { page_root, model_name, model_category } = payload;
    const response = await fieldsAdminService.getAdminFields(
      page_root,
      model_name,
      model_category
    );
    if (!response) {
      throw new Error("Retrieve Admin Fields Failed");
    }

    return response;
  }
);

export const getAdminField = createAsyncThunk(
  "admin/get-admin-field",
  async (payload: any) => {
    const response = await fieldsAdminService.getAdminField(payload);
    if (!response) {
      throw new Error("Retrieve Admin Field Failed");
    }

    return response;
  }
);

export const createAdminField = createAsyncThunk(
  "admin/create-admin-field",
  async (payload: any) => {
    const response = await fieldsAdminService.createAdminField(payload);
    if (!response) {
      throw new Error("Create Admin Field Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const editAdminField = createAsyncThunk(
  "admin/edit-admin-field",
  async (payload: any) => {
    const response = await fieldsAdminService.editAdminField(payload);
    if (!response) {
      throw new Error("Edit Admin Field Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const deleteAdminField = createAsyncThunk(
  "admin/delete-admin-field",
  async (payload: any) => {
    const response = await fieldsAdminService.removeAdminField(payload);
    if (!response) {
      throw new Error("Delete Admin Field Failed!");
    }

    return response;
  }
);

export const retrieveAdminFields = createAsyncThunk(
  "admin/retrieve-admin-fields",
  async (payload: any) => {
    const { page_root, model_name, model_category } = payload;
    const response = await fieldsAdminService.retrieveAdminFields(
      page_root,
      model_name,
      model_category
    );
    if (!response) {
      throw new Error("Retrieve Admin Fields Failed!");
    }

    return response;
  }
);
